const my = {
  almostThere: 'Almost there! You still have few more questions to go.',
  continue: 'Terus',
  visitUs: 'Lawati Kami',
  newSubmission: 'Penyerahan Baru',
  termUse: 'Terma Penggunaan',
  perviousPage: 'Previous page',
  chooseInterestCource: 'Pilihkan kursus yang minat:',
  ignoreCourse: 'Kursus (Abaikan jika tidak berkenaan)',
  hearAboutUs: 'Bagaimanakah anda mengetahui kami? (tidak diwajibkan)',
  hearAboutPlaceholder: 'Contoh: Facebook',
  agreeTo: 'Saya bersetuju dengan',
  and: 'dan ',
  privacyStatement: 'Penyataan Privasi',
  ofIkey: 'daripada iKEY Edutech Sdn Bhd',
  acceptTnC: 'Sila accept term of use & privacy statement',
  agree: 'Saya bersetuju dengan',
  termCondition: 'Terma dan Syarat',
  of: 'daripada {0}',
  submit: 'Hantar',
  page: 'Halaman {0}',

  learnerInfo: 'Informasi pelajar',
  profile: 'Gambar Profil',
  name: 'Nama (Mengikut NRIC)',
  enterName: 'Sila isikan nama penuh',
  gender: 'Jantina',
  chooseGender: 'Sila pilihkan jantina',
  male: 'Lelaki',
  female: 'Perempuan',
  notSpecify: 'Not Specify',
  dob: 'Tarikh Lahir',
  selectDob: 'Sila pilihkan tarikh lahir',
  ic: 'MyKid/No.Pasport/No. Kad Pengenalan',
  enterIc: 'Sila isikan MyKid/No.Pasport/No. Kad Pengenalan',
  contactIc: 'No.Pasport/No. Kad Pengenalan',
  enterContactIc: 'Sila isikan No.Pasport/No. Kad Pengenalan',
  birthCert: 'No. Surat Beranak',
  enterbirthCert: 'Sila isikan no. surat beranak',
  schoolName: 'Nama Sekolah',
  enterSchoolName: 'Sila isikan nama sekolah',
  standard: 'Darjah / Tahap / Gred',
  selectStandard: 'Sila pilihkan darjah/tahap/gred',
  unit: 'No. Unit Rumah',
  enterUnit: 'Sila isikan no. unit rumah',
  streetName: 'Nama Jalan Rumah',
  enterStreet: 'Sila isikan nama jalan rumah',
  city: 'Bandar',
  enterCity: 'Sila isikan bandar',
  postalCode: 'Poskod',
  enterPostal: 'Sila isikan poskod cth.12345',
  country: 'Negara',
  selectCountry: 'Sila pilihkan negara',
  state: 'Negeri / Wilayah',
  selectState: 'Sila pilihkan negeri/ wilayah',
  medicalCondition: 'Maklumat kesihatan/ Alahan',
  childSpecialInfo: 'Adakah apa-apa informasi penting daripada pelajar?',
  medicalPlaceholder: 'Contoh: Alahan pada udang.',
  next: 'Seterusnya',
  prev: 'Sebelumnya',
  sameAbove: 'Sama seperti di atas',
  contactPerson1: 'Hubungan dengan pelajar (Diwajibkan)',
  email: 'Emel',
  enterEmail: 'Sila isikan emel',
  relationship: 'Hubungan',
  selectRelationship: 'Sila pilihkan hubungan',
  contactNumber: 'Nombor telefon',
  enterContact: 'Sila isikan no. telefon cth: 0123456789',
  dialingCode: 'Dialing Code',
  selectDialingCode: 'Sila pilihkan kod dailan',
  race: 'Perlumbaan',
  enterRace: 'Sila isikan perlumbaan',
  spoken: 'Bahasa ibunda',
  enterSpoken: 'Sila isikan bahasa ibunda',
  siblingNo: 'Bilangan adik-beradik',
  enterSibling: 'Sila isikan bilangan adik-beradik',
  position: 'Sususan antara adik-beradik',
  enterPosition: 'Sila isikan sususan antara adik-beradik',
  placeBirth: 'Tempat Kelahiran',
  enterPlaceBirth: 'Sila isikan tempat kelahiran',
  nation: 'Kewarganegaraan',
  enterNation: 'Sila isikan kewarganegaraan',
  companyName: 'Nama Syarikat',
  enterCompany: 'Sila isikan nama syarikat',
  religion: 'Agama',
  enterReligion: 'Sila isikan agama',
  marital: 'Status Perkahwinan',
  enterMarital: 'Sila isikan status perkahwinan',
  job: 'Pekerjaan',
  enterJob: 'Sila isikan pekerjaan',
  vehicle: 'No. Kenderaan',
  enterVehicle: 'Sila isikan no. kenderaan',
  primaryContact: 'Hubungan utama',
  contactPerson2: 'Hubungan {0}',
  moreContact: 'Tambah Hubungan lain',
  loading: 'Loading',
  enrollmentForm: 'Borang pendaftaran',
  submittedForm: 'Borang pendaftaran telah dihantarkan',
  anotherPage: 'Halaman lain yang perlu dikunjungi ...',

  //Relationship
  auntie: 'Auntie',
  caregiver: 'Caregiver',
  driver: 'Driver',
  emergencyContact: 'Emergency Contact',
  father: 'Father',
  grandfather: 'Grandfather',
  grandmother: 'GrandMother',
  mother: 'Mother',
  nanny: 'Nanny',
  notSpecify: 'Not Specify',
  spouse: 'Spouse',
  studentself: 'Student himself/herself',
  uncle: 'Uncle',

  //Error Message
  nameRequired: 'Student Name is required',
  genderRequired: 'Sila select a gender',
  dobRequired: 'Date of Birth is required',
  icRequired: 'IC no. is required',
  icNotCorrect: 'IC no. not correct',
  unitRequired: 'Sila enter unit no.',
  streetRequired: ' Sila enter street name',
  postalCodeRequired: 'Postal code is required',
  countryRequired: 'Sila select a country',
  stateRequired: 'Sila select State / Province',
  emailIncorrect: 'Sila Enter Correct Email',
  emailRequired: 'Email is required',
  relationshipRequired: 'Relationship is required',
  dialingCodeRequired: 'Dialing code is required',
  contactNumberRequired: 'Contact number is required',
  agreeTncRequired:
    'Sila bersetuju dengan terma dan syarat dan penyataan privasi daripada iKEY Edutech Sdn Bhd',
  agreeCenterRequired: 'Sila bersetuju dengan terma dan syarat daripada {0}',

  // Directory

  // Center
  learnerAge: 'Learner Age',
  relatedCourse: 'Related Courses',
  webiste: 'Website',
  openHours: 'Open Hours',
  fullfillRequirement: 'Fullfill your requirement?',
  address: 'Address',
  nextSection: 'Next Section (Compulsory)',
  course: 'Course',
};

export default my;
