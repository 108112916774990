import React from 'react';
import { Badge } from 'reactstrap';

export default class Tags extends React.PureComponent {
  constructor(props) {
    super(props);

    this.color = [
      '#FFAEF0',
      '#D5BEED',
      '#BBBBFF',
      '#97BBE8',
      '#8ACECB',
      '#98E2B6',
      '#CCCC99',
      '#F9B973',
      '#FF947B',
      '#BF9D8E',
    ];
  }

  getColor = (index) => {
    let colorIndex = index % this.color.length;
    // console.log('color Index', this.color[colorIndex]);
    return this.color[colorIndex];
  };

  render() {
    const { centerTag } = this.props;
    // console.log('tag -', centerTag);
    return (
      <div className='row p-0'>
        {centerTag.map((item, index) => {
          return (
            <h5>
              {/* <p className='d-inline-block' style={{ color:  }}></p> */}
              <Badge
                key={index}
                style={{
                  backgroundColor: this.getColor(index),
                  marginRight: 10,
                  marginBottom: 5,
                  textTransform: 'capitalize',
                }}
              >
                {!item.tagName.includes('?') ? item.tagName : null}
              </Badge>
            </h5>
          );
        })}
      </div>
    );
  }
}
