const en = {
  almostThere: "Almost there! You still have few more questions to go.",
  continue: "Continue",
  visitUs: "Visit Us",
  newSubmission: "New Submission",
  termUse: "Term of Use",
  perviousPage: "Previous page",
  chooseInterestCource: "Choose Interested Course",
  ignoreCourse: "Course (Ignore if none)",
  hearAboutUs: "How did you hear about us? (Optional)",
  hearAboutPlaceholder: "E.g: FaceBook",
  agreeTo: "I agree to the",
  and: "and",
  privacyStatement: "Privacy Statement",
  ofIkey: "of iKEY Edutech Sdn Bhd",
  acceptTnC: "Please accept term of use & privacy statement",
  agree: "I agree",
  termCondition: "Term and Condition",
  of: "of {0}",
  submit: "Submit",
  page: "Page {0}",

  learnerInfo: "Learner's Info",
  profile: "Profile Picture",
  name: "Name (As per NRIC)",
  enterName: "Please enter full name",
  otherName: "Other (Optional)",
  enterOtherName: "Eg. Chinese Name, Tamil Name and etc.",
  gender: "Gender",
  chooseGender: "Choose a gender",
  male: "Male",
  female: "Female",
  notSpecify: "Not Specify",
  dob: "Date of Birth",
  selectDob: "Please select date of birth",
  ic: "MyKid/No.Passport/IC No.",
  enterIc: "Please enter MyKid/No.Pasport/IC No.",
  contactIc: "No.Passport/IC No.",
  enterContactIc: "Please enter No.Pasport/IC No.",
  birthCert: "Birth Cert no.",
  enterbirthCert: "Please enter birth cert number",
  schoolName: "School Name",
  enterSchoolName: "Please enter school name",
  standard: "Standard / Level / Grade",
  selectStandard: "Please select standard / level / grade",
  unit: "Home Unit No.",
  enterUnit: "Please enter home unit no.",
  streetName: "Home Street Name",
  enterStreet: "Please enter home street name",
  city: "City",
  enterCity: "Please enter city",
  postalCode: "Postal Code",
  enterPostal: "Please enter postal code e.g.12345",
  country: "Country",
  selectCountry: "Please select a country",
  state: "State / Province",
  selectState: "Please select State / Province",
  medicalCondition: "Medical conditions/allergies",
  childSpecialInfo: "Any special information to let us know about the child?",
  medicalPlaceholder: "E.g: Allergic to peanuts",
  next: "Next",
  prev: "Prev",
  sameAbove: "Same as above",
  contactPerson1: "Contact Person 1 (Compulsory)",
  email: "Email",
  enterEmail: "Please enter email",
  relationship: "Relationship",
  selectRelationship: "Please select relationship",
  contactNumber: "Contact Number",
  enterContact: "Please enter contact number E.g.0123456789",
  dialingCode: "Dialing Code",
  selectDialingCode: "Please select dialing code",
  race: "Race",
  enterRace: "Please enter race",
  spoken: "Spoken Language",
  enterSpoken: "Please enter spoken language",
  siblingNo: "Sibling No.",
  enterSibling: "Please enter sibling no.",
  position: "Position In Family",
  enterPosition: "Please enter position in family",
  placeBirth: "Place of Birth",
  enterPlaceBirth: "Please enter place of birth",
  nation: "Nationality",
  enterNation: "Please enter nationality",
  companyName: "Company Name",
  enterCompany: "Please enter company name",
  religion: "Religion",
  enterReligion: "Please enter religion",
  marital: "Marital Status",
  enterMarital: "Please enter marital status",
  job: "Occupation",
  enterJob: "Please enter occupation",
  vehicle: "Vehicle No.",
  enterVehicle: "Please enter vehicle no.",
  primaryContact: "Primary Contact Person",
  contactPerson2: "Contact Person {0}",
  moreContact: "Add More Contact Person",
  enterAnswer: "Please enter your answer",
  loading: "Loading",
  enrollmentForm: "Enrollment Form",
  submittedForm: "You have submitted the enrollment form.",
  anotherPage: "Another page to go…",

  //Relationship
  auntie: "Auntie",
  caregiver: "Caregiver",
  driver: "Driver",
  emergencyContact: "Emergency Contact",
  father: "Father",
  grandfather: "Grandfather",
  grandmother: "GrandMother",
  mother: "Mother",
  nanny: "Nanny",
  notSpecify: "Not Specify",
  spouse: "Spouse",
  studentself: "Student himself/herself",
  uncle: "Uncle",

  //Error Message
  nameRequired: "Student Name is required",
  genderRequired: "Please select a gender",
  dobRequired: "Date of Birth is required",
  icRequired: "IC no. is required",
  icNotCorrect: "IC no. not correct",
  unitRequired: "Please enter unit no.",
  streetRequired: " Please enter street name",
  postalCodeRequired: "Postal code is required",
  countryRequired: "Please select a country",
  stateRequired: "Please select State / Province",
  emailIncorrect: "Please Enter Correct Email",
  emailRequired: "Email is required",
  relationshipRequired: "Relationship is required",
  dialingCodeRequired: "Dialing code is required",
  contactNumberRequired: "Contact number is required",
  agreeTncRequired:
    "Please accept term of use & privacy statement of iKEY Edutech Sdn Bhd",
  agreeCenterRequired: "Please accept term and condition of {0}",

  // Directory

  // Center
  learnerAge: "Learner Age",
  relatedCourse: "Related Courses",
  webiste: "Website",
  openHours: "Open Hours",
  fullfillRequirement: "Fullfill your requirement?",
  address: "Address",
  nextSection: "Next Section (Compulsory)",
  course: "Course",
};

export default en;
