import React from 'react';

import '../style/EnrolForm.css';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'react-string-format';

const emailValidate = /[\w-]+@([\w-]+\.)+[\w-]+/;
export default class EnrolForm2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      message: '',
      submitted: false,

      sameAbove: false,
      enableExtendedField: this.props.centerData.enableExtendedField,

      formData: '',

      contact1: '',
      contact1Email: '',
      emailError: true,
      contact1Relation: '',
      contact1Dialing: '',
      contact1Phone: '',
      contact1Check: true,
      // ExtraField 1
      contact1Company: '',
      contact1Job: '',
      contact1Marital: '',
      contact1National: '',
      contact1Religion: '',
      contact1Vehicle: '',

      contact2Enable: true,
      contact2: '',
      contact2Email: '',
      contact2Relation: '',
      contact2Dialing: '',
      contact2Phone: '',
      contact2Check: false,
      // ExtraField 2
      contact2Company: '',
      contact2Job: '',
      contact2Marital: '',
      contact2National: '',
      contact2Religion: '',
      contact2Vehicle: '',

      contactPersons: [],
    };
  }

  componentDidMount() {
    if (this.state.contactPersons.length === 0) {
      this.addMoreContact();
    } else {
      console.log('init contact - ', this.state.contactPersons);
    }

    console.log(this.props.language.ic.split('/')[(1, 2)]);
  }

  isValid() {
    const emailValidate = /[\w-]+@([\w-]+\.)+[\w-]+/;

    let valid = true;

    this.state.contactPersons.map((item) => {
      console.log(item, item.Name);
      if (item.Name.length === 0) {
        valid = false;
      } else if (item.DialingCode === '') {
        valid = false;
      } else if (item.RelationshipId === '') {
        valid = false;
      } else if (item.PhoneNumber.length === 0) {
        valid = false;
      }
    });

    console.log('valid - ', valid);
    return valid;
  }

  addMoreContact() {
    //Contact Person Details
    let newContact = this.state.contactPersons;
    newContact.push({
      Name: '',
      Email: '',
      DialingCode: '',
      PhoneNumber: '',
      ICNo: '',
      Primary: newContact.length === 0 ? true : false,
      RelationshipId: '',
      CompanyName: '',
      Occupation: '',
      Nationality: '',
      Maritial: '',
      Religion: '',
      VehicleNo: '',
      Race: '',
    });

    this.setState({ contactPersons: newContact }, () => {
      console.log(this.state.contactPersons);
    });
  }

  removeMoreContact(index) {
    console.log('remove contact ', index);
    let newContact = [...this.state.contactPersons];

    newContact.splice(index, 1);

    this.setState({ contactPersons: newContact });
  }

  setSameAsAbove(checked) {
    this.setState({ sameAbove: checked }, () => {
      let newContact = [...this.state.contactPersons];
      if (checked) {
        if (this.props.centerData.enableExtendedField) {
          newContact[0] = {
            ...newContact[0],
            Religion: this.props.formData.Religion,
            Nationality: this.props.formData.Nationality,
            Email: this.props.formData.StudentEmail,
            ICNo: this.props.formData.IdentityCard,
            Name: this.props.formData.StudentName,
            DialingCode: this.props.contact[0],
            PhoneNumber: this.props.contact[1],
          };

          this.setState({ contactPersons: newContact }, () => {
            console.log(newContact);
          });
        } else {
          newContact[0] = {
            ...newContact[0],
            Email: this.props.formData.StudentEmail,
            ICNo: this.props.formData.IdentityCard,
            Name: this.props.formData.StudentName,
            DialingCode: this.props.contact[0],
            PhoneNumber: this.props.contact[1],
          };

          this.setState({ contactPersons: newContact }, () => {
            console.log(newContact);
          });
        }
      } else {
        if (this.props.centerData.enableExtendedField) {
          newContact[0] = {
            ...newContact[0],
            Email: '',
            Religion: '',
            Nationality: '',
            ICNo: '',
            Name: '',
            DialingCode: '',
            PhoneNumber: '',
          };

          this.setState({ contactPersons: newContact }, () => {
            console.log(newContact);
          });
        } else {
          newContact[0] = {
            ...newContact[0],
            Email: '',
            Name: '',
            ICNo: '',
            DialingCode: '',
            PhoneNumber: '',
          };

          this.setState({ contactPersons: newContact }, () => {
            console.log(newContact);
          });
        }
      }
    });
  }

  handlePrimaryCt(checked, index) {
    // console.log('pri', index, checked);
    let newContact = [...this.state.contactPersons];
    for (var i = 0; i < newContact.length; i++) {
      if (i === index) {
        if (checked) {
          newContact[i] = {
            ...newContact[i],
            Primary: checked,
          };
        } else {
          if (index === 0) {
            newContact[0] = {
              ...newContact[0],
              Primary: true,
            };
          } else {
            newContact[0] = {
              ...newContact[0],
              Primary: true,
            };
            newContact[i] = {
              ...newContact[i],
              Primary: checked,
            };
          }
        }
      } else {
        newContact[i] = {
          ...newContact[i],
          Primary: false,
        };
      }

      this.setState({ contactPersons: newContact }, () => {
        console.log('setPri -', this.state.contactPersons[index]);
      });
    }

    // if (this.state.contact2Enable && this.state.contact1Check) {
    //   this.setState({ contact2Check: checked, contact1Check: !checked });
    // } else {
    //   this.setState({ contact1Check: checked, contact2Check: !checked });
    // }
  }

  switchLanguage(name) {
    const { language } = this.props;
    switch (name.id) {
      case 1:
        return language.father;
        break;
      case 2:
        return language.mother;
        break;
      case 3:
        return language.grandfather;
        break;
      case 4:
        return language.grandmother;
        break;
      case 5:
        return language.auntie;
        break;
      case 6:
        return language.uncle;
        break;
      case 7:
        return language.notSpecify;
        break;
      case 8:
        return language.caregiver;
        break;
      case 9:
        return language.nanny;
        break;
      case 10:
        return language.driver;
        break;
      case 11:
        return language.studentself;
        break;
      case 12:
        return language.spouse;
        break;
      case 13:
        return language.emergencyContact;
        break;
      default:
        return name.name;
        break;
    }
  }

  goPrev() {}

  onSubmitForm2(event) {
    event.preventDefault();

    console.log('submit -', this.state.contactPersons);
    this.setState({ submitted: true }, () => {
      if (this.isValid()) {
        let newForm = {
          ...this.props.formData,
          ContactPersonDetails: this.state.contactPersons,
        };

        this.setState({ formData: newForm }, () => {
          console.log('extraForm -', newForm);
          this.props.setFormData(newForm);
          this.props.scrollToPage3();
        });
      }
      // else {
      //   alert('Something Missing');
      // }
    });
  }

  render() {
    const { language, centerData, countryOption } = this.props;
    console.log('form1 - ', this.props.languageType);
    return (
      <div className='container-fluid' style={{ backgroundColor: '#f2f5fd' }}>
        <section id='enrol-form' className='container'>
          <div className='container mt-4 mb-2'>
            <div className='row px-5 my-3 justify-content-between align-items-center'>
              <h5>{language.enrollmentForm}</h5>
              <h6 className='text-right'>
                {format(language.page, `${'2/' + this.props.pageNum}`)}
              </h6>
            </div>
          </div>

          {this.state.contactPersons.map((item, index) => {
            return (
              <div
                key={index}
                className={`bg-white shadow p-5 ${index > 0 && 'mt-4'}`}
              >
                <h3 className='mb-3 h3 text-capitalize'>
                  {index === 0
                    ? language.contactPerson1
                    : format(language.contactPerson2, index + 1)}
                  {index > 0 && (
                    <i
                      className='fa fa-remove remove-icon float-right'
                      onClick={() => this.removeMoreContact(index)}
                    ></i>
                  )}
                </h3>
                <form autoComplete='off'>
                  {index === 0 && (
                    <div className='form-check mt-3 mb-3'>
                      <input
                        type='checkbox'
                        className='form-check-input'
                        id='sameAbove'
                        name='sameAbove'
                        onChange={(event) =>
                          this.setSameAsAbove(event.target.checked)
                        }
                      />
                      <label className='form-check-label' for='sameAbove'>
                        {language.sameAbove}
                      </label>
                    </div>
                  )}
                  {/* <!-- Name + Email--> */}
                  <div className='row'>
                    <div className='form-group col-md-6'>
                      <label className='col-form-label pt-0'>
                        {language.name} <span>*</span>
                      </label>
                      <input
                        type='text'
                        name='name'
                        className={`form-control form-control-md ${
                          this.state.submitted &&
                          this.state.contactPersons[index].Name.length === 0
                            ? 'is-invalid'
                            : null
                        }`}
                        placeholder={language.enterName}
                        required
                        value={this.state.contactPersons[index].Name}
                        onChange={(event) => {
                          let contact = [...this.state.contactPersons];
                          contact[index] = {
                            ...contact[index],
                            Name: event.target.value,
                          };

                          this.setState({ contactPersons: contact });
                        }}
                      />
                      {/* <!-- Validation Error --> */}
                      <div className='invalid-feedback'>
                        <div>Name is required</div>
                      </div>
                    </div>

                    <div className='form-group col-md-6'>
                      <label className='col-form-label pt-0'>
                        {/* <span>*</span> */}
                        {language.email}
                      </label>
                      <input
                        type='email'
                        name='email'
                        className={`form-control form-control-md ${
                          this.state.submitted &&
                          this.state.contactPersons[index].Email.length > 0 &&
                          !emailValidate.test(
                            this.state.contactPersons[index].Email
                          )
                            ? 'is-invalid'
                            : null
                        }`}
                        placeholder={language.enterEmail}
                        required
                        value={this.state.contactPersons[index].Email}
                        onChange={(event) => {
                          let contact = [...this.state.contactPersons];
                          contact[index] = {
                            ...contact[index],
                            Email: event.target.value,
                          };

                          this.setState({ contactPersons: contact });
                        }}
                      />
                      {/* <!-- Validation Error --> */}
                      <div className='invalid-feedback'>
                        {/* {this.state.contactPersons[index].Email.length ===
                          0 && <div>Email is required</div>} */}
                        {this.state.contactPersons[index].Email.length > 0 &&
                          !emailValidate.test(
                            this.state.contactPersons[index].Email
                          ) && <div>Please Enter Correct Email</div>}
                      </div>
                    </div>
                  </div>
                  {/* IC No */}
                  <div className='form-group'>
                    <label className='col-form-label pt-0'>
                      {language.contactIc}
                    </label>
                    <input
                      type='email'
                      name='email'
                      className={`form-control form-control-md `}
                      placeholder={language.enterContactIc}
                      required
                      value={this.state.contactPersons[index].ICNo}
                      onChange={(event) => {
                        let contact = [...this.state.contactPersons];
                        contact[index] = {
                          ...contact[index],
                          ICNo: event.target.value,
                        };

                        this.setState({ contactPersons: contact });
                      }}
                    />
                    {/* <!-- Validation Error --> */}
                    <div className='invalid-feedback'>
                      <div>Please Enter Correct Email</div>
                      <div>Email is required</div>
                    </div>
                  </div>
                  {/* <!-- Relationship --> */}
                  <div className='form-group'>
                    <label>
                      {language.relationship} <span>*</span>
                    </label>
                    <select
                      defaultValue=''
                      value={this.state.contactPersons[index].RelationshipId}
                      className={`form-control custom-select ${
                        this.state.submitted &&
                        this.state.contactPersons[index].RelationshipId === ''
                          ? 'is-invalid'
                          : null
                      }`}
                      onChange={(event) => {
                        let contact = [...this.state.contactPersons];
                        contact[index] = {
                          ...contact[index],
                          RelationshipId: event.target.value,
                        };

                        this.setState({ contactPersons: contact });
                      }}
                    >
                      <option value='' disabled>
                        {language.selectRelationship}
                      </option>
                      {centerData.relationshipDetails.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {this.switchLanguage(item)}
                          </option>
                        );
                      })}
                    </select>
                    {/* // <!-- Validation Error --> */}
                    <div className='invalid-feedback'>
                      <div>{language.selectRelationship}</div>
                    </div>
                  </div>
                  {/* // <!-- Dialing Code + Contact --> */}
                  <label className='contact-label'>
                    {language.contactNumber} <span>*</span>
                  </label>
                  <div className='row'>
                    <div className='form-group col-md-3'>
                      <select
                        defaultValue=''
                        value={this.state.contactPersons[index].DialingCode}
                        className={`form-control custom-select ${
                          this.state.submitted &&
                          this.state.contactPersons[index].DialingCode === ''
                            ? 'is-invalid'
                            : null
                        }`}
                        onChange={(event) => {
                          let contact = [...this.state.contactPersons];
                          contact[index] = {
                            ...contact[index],
                            DialingCode: event.target.value,
                          };

                          this.setState({ contactPersons: contact });
                        }}
                      >
                        <option value='' disabled>
                          {language.selectDialingCode}
                        </option>
                        {countryOption.map((item, index) => {
                          return (
                            <option key={index} value={item.dialingCode}>
                              {item.dialingCode}
                            </option>
                          );
                        })}
                      </select>
                      {/* // <!-- Validation Error --> */}
                      <div className='invalid-feedback'>
                        <div>{language.selectDialingCode}</div>
                      </div>
                    </div>
                    <div className='form-group col-md-9'>
                      {/* <!-- <label className="col-form-label pt-0">Contact</label> --> */}
                      <input
                        type='tel'
                        name='contact'
                        className={`form-control form-control-md ${
                          this.state.submitted &&
                          this.state.contactPersons[index].PhoneNumber
                            .length === 0
                            ? 'is-invalid'
                            : null
                        }`}
                        placeholder={language.enterContact}
                        required
                        value={this.state.contactPersons[index].PhoneNumber}
                        onChange={(event) => {
                          let contact = [...this.state.contactPersons];
                          contact[index] = {
                            ...contact[index],
                            PhoneNumber: event.target.value,
                          };

                          this.setState({ contactPersons: contact });
                        }}
                      />
                      {/* <!-- Validation Error --> */}
                      <div className='invalid-feedback'>
                        <div>Contact number is required</div>
                      </div>
                    </div>
                  </div>
                  {/* Extra Field */}
                  {this.state.enableExtendedField ? (
                    <div>
                      {/* Company + Job */}
                      <div className='row'>
                        <div className='form-group col-md-6'>
                          <label className='col-form-label pt-0'>
                            {language.companyName}
                          </label>
                          <input
                            type='text'
                            name='companyName'
                            className={`form-control form-control-md `}
                            placeholder={language.enterCompany}
                            value={this.state.contactPersons[index].CompanyName}
                            onChange={(event) => {
                              let contact = [...this.state.contactPersons];
                              contact[index] = {
                                ...contact[index],
                                CompanyName: event.target.value,
                              };

                              this.setState({ contactPersons: contact });
                            }}
                          />
                        </div>

                        <div className='form-group col-md-6'>
                          <label className='col-form-label pt-0'>
                            {language.job}
                          </label>
                          <input
                            type='text'
                            name='job'
                            className={`form-control form-control-md `}
                            placeholder={language.enterJob}
                            value={this.state.contactPersons[index].Occupation}
                            onChange={(event) => {
                              let contact = [...this.state.contactPersons];
                              contact[index] = {
                                ...contact[index],
                                Occupation: event.target.value,
                              };

                              this.setState({ contactPersons: contact });
                            }}
                          />
                        </div>
                      </div>

                      {/* Religion + Nationality */}
                      <div className='row'>
                        <div className='form-group col-md-6'>
                          <label className='col-form-label pt-0'>
                            {language.religion}
                          </label>
                          <input
                            type='text'
                            name='religion'
                            className={`form-control form-control-md `}
                            placeholder={language.enterReligion}
                            value={this.state.contactPersons[index].Religion}
                            onChange={(event) => {
                              let contact = [...this.state.contactPersons];
                              contact[index] = {
                                ...contact[index],
                                Religion: event.target.value,
                              };

                              this.setState({ contactPersons: contact });
                            }}
                          />
                        </div>

                        <div className='form-group col-md-6'>
                          <label className='col-form-label pt-0'>
                            {language.nation}
                          </label>
                          <input
                            type='text'
                            name='nation'
                            className={`form-control form-control-md `}
                            placeholder={language.enterNation}
                            value={this.state.contactPersons[index].Nationality}
                            onChange={(event) => {
                              let contact = [...this.state.contactPersons];
                              contact[index] = {
                                ...contact[index],
                                Nationality: event.target.value,
                              };

                              this.setState({ contactPersons: contact });
                            }}
                          />
                        </div>
                      </div>

                      {/* Marital + Vehicle */}
                      <div className='row'>
                        <div className='form-group col-md-6'>
                          <label className='col-form-label pt-0'>
                            {language.marital}
                          </label>
                          <input
                            type='text'
                            name='marital'
                            className={`form-control form-control-md `}
                            placeholder={language.enterMarital}
                            value={this.state.contactPersons[index].Maritial}
                            onChange={(event) => {
                              let contact = [...this.state.contactPersons];
                              contact[index] = {
                                ...contact[index],
                                Maritial: event.target.value,
                              };

                              this.setState({ contactPersons: contact });
                            }}
                          />
                        </div>

                        <div className='form-group col-md-6'>
                          <label className='col-form-label pt-0'>
                            {language.vehicle}
                          </label>
                          <input
                            type='text'
                            name='vehicle'
                            className={`form-control form-control-md `}
                            placeholder={language.enterVehicle}
                            value={this.state.contactPersons[index].VehicleNo}
                            onChange={(event) => {
                              let contact = [...this.state.contactPersons];
                              contact[index] = {
                                ...contact[index],
                                VehicleNo: event.target.value,
                              };

                              this.setState({ contactPersons: contact });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className='form-check mt-2 mb-3'>
                    <input
                      type='checkbox'
                      className={`form-check-input `}
                      id={`${'motherCheck' + index}`}
                      checked={this.state.contactPersons[index].Primary}
                      onChange={(event) =>
                        this.handlePrimaryCt(event.target.checked, index)
                      }
                    />
                    <label
                      className='form-check-label'
                      for={`${'motherCheck' + index}`}
                    >
                      {language.primaryContact}
                    </label>

                    <div className=' invalid-feedback mb-2 mt-2'>
                      <span className='danger'>{'message'}</span>
                    </div>
                    {index + 1 === this.state.contactPersons.length &&
                    this.state.contactPersons.length < 5 ? (
                      <>
                        <div className='row mt-4'>
                          <div className='col-md-12 text-center'>
                            <button
                              disabled={this.state.contactPersons.length > 4}
                              className='btn-next'
                              type='submit'
                              onClick={() => this.addMoreContact()}
                            >
                              {language.moreContact}
                            </button>
                          </div>
                        </div>
                      </>
                    ) : null}
                    {index + 1 === this.state.contactPersons.length && (
                      <div className='row mt-4'>
                        <div className='col-md-12 text-center'>
                          <button
                            className='btn-prev'
                            type='submit'
                            onClick={() => this.props.backToPage1()}
                          >
                            {language.prev}
                          </button>
                          <button
                            className='btn-next justify-content-between'
                            type='submit'
                            onClick={(event) => this.onSubmitForm2(event)}
                          >
                            {language.next}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            );
          })}
        </section>
      </div>
    );
  }
}
