import React from 'react';
import '../style/Navbar.css';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import icon from '../assets/logo-icon.png';
import word from '../assets/logo-word.png';
import playstore from '../assets/google-play-logo.png';
import appstore from '../assets/app-store-logo.png';
import gallery from '../assets/app-gallery-logo.png';

export default class NavigationBar extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,

      activeLanguage: '',
    };

    this.language = [
      { id: 'en', name: 'English' },
      { id: 'zh', name: '中文' },
      { id: 'my', name: 'Malay' },
    ];
  }

  toggleMenu() {
    $('#menu-section').slideToggle();

    return false;
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  switchLanguage = (id) => {
    let currentLanguage = this.language
      .filter((item) => {
        return item.id === id;
      })
      .map((i) => {
        return i.name;
      });
    console.log(currentLanguage);
    this.setState({ activeLanguage: currentLanguage }, () => {
      this.props.setLanguage(id);
    });
  };

  directToIkey(link) {
    window.open('https://www.ikeyedutech.com.my/' + link);
  }

  render() {
    const { noMenu } = this.props;
    return (
      <>
        <Navbar className='desktop-header fixed-top py-3' expand='lg'>
          <NavbarBrand className='container'>
            <Link className='logo' to='/'>
              <img className='logo-img' src={icon} />
              <img className='logo-word' src={word} />
            </Link>
          </NavbarBrand>
          <Nav className='container-fluid navbar-right justify-content-end'>
            <NavItem className='mr-3'>
              <UncontrolledDropdown>
                <DropdownToggle
                  style={{ backgroundColor: '#e71f4b', width: 75 }}
                >
                  {this.state.activeLanguage
                    ? this.state.activeLanguage
                    : 'English'}
                </DropdownToggle>
                <DropdownMenu right>
                  {this.language.map((item) => {
                    return (
                      <DropdownItem
                        onClick={() => this.switchLanguage(item.id)}
                      >
                        {item.name}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>
            {!noMenu && (
              <NavItem>
                <a onClick={() => this.toggleMenu()} className='menu'>
                  <i className='fa fa-bars'></i> Menu
                </a>
              </NavItem>
            )}
          </Nav>
        </Navbar>
        <section id='menu-section' className='menu-section hide'>
          <div className='container'>
            <div className='row menu-row'>
              <div className='columns col-lg-3 col-md-3 col-sm-6 col-12'>
                <ul>
                  <li className='title-header'>ABOUT iKEY</li>
                  <li>
                    <a href='#' onClick={() => this.directToIkey('index.html')}>
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      href='#'
                      onClick={() => this.directToIkey('our-story.html')}
                    >
                      iKEY Story
                    </a>
                  </li>
                  <li>
                    <a
                      href='#'
                      onClick={() => this.directToIkey('meet-the-team.html')}
                    >
                      Meet The Team
                    </a>
                  </li>
                  <li>
                    <a
                      href='#'
                      onClick={() => this.directToIkey('announcement.html')}
                    >
                      Announcement
                    </a>
                  </li>
                  <li>
                    <a href='#' onClick={() => this.directToIkey('mdec.html')}>
                      SME Digitalization Initiative
                    </a>
                  </li>
                  <li>
                    <a
                      href='#'
                      onClick={() =>
                        this.directToIkey(
                          'RHB-reflex-premium-plus-for-education-campaign.html'
                        )
                      }
                    >
                      A complete solution with RHB x iKEY x Financio x
                      Ringgitpay
                    </a>
                  </li>
                </ul>
              </div>
              <div className='columns col-lg-3 col-md-3 col-sm-6 col-12'>
                <ul>
                  <li className='title-header'>PRODUCT</li>
                  <li>
                    <a
                      href='#'
                      onClick={() => this.directToIkey('business.html')}
                    >
                      Centre Management System
                    </a>
                  </li>
                  <li>
                    <a
                      href='#'
                      onClick={() => this.directToIkey('education-apps.html')}
                    >
                      iKEY Educator App
                    </a>
                  </li>
                  <li>
                    <a
                      href='#'
                      onClick={() => this.directToIkey('parent-apps.html')}
                    >
                      iKEY Parent App
                    </a>
                  </li>
                  <li>
                    <a
                      href='#'
                      onClick={() => this.directToIkey('student-apps.html')}
                    >
                      iKEY Student App
                    </a>
                  </li>
                </ul>
              </div>
              <div className='columns col-lg-3 col-md-3 col-sm-6 col-12'>
                <ul>
                  <li className='title-header'>HELP</li>
                  <li>
                    <a data-toggle='modal' data-target='#supportModal' href='#'>
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <a href='#' onClick={() => this.directToIkey('faq.html')}>
                      FAQ
                    </a>
                  </li>
                  <li>
                    <a href='#' onClick={() => this.directToIkey('terms.html')}>
                      Terms
                    </a>
                  </li>
                  <li>
                    <a
                      href='#'
                      onClick={() => this.directToIkey('privacy-policy.html')}
                    >
                      Privacy
                    </a>
                  </li>
                  <li>
                    <a
                      href='#'
                      onClick={() => this.directToIkey('training.html')}
                    >
                      Live Webinar Registration
                    </a>
                  </li>
                </ul>
              </div>
              <div className='columns col-lg-3 col-md-3 col-sm-6 col-12'>
                <ul>
                  <li className='title-header'>DOWNLOAD APPS</li>
                  <li>
                    <a
                      className='download'
                      target='_blank'
                      href='https://play.google.com/store/apps/details?id=my.com.ikeyedutect.parent.full&hl=en&gl=US'
                    >
                      <img src={playstore} />
                    </a>
                  </li>
                  <li>
                    <a
                      className='download'
                      target='_blank'
                      href='https://apps.apple.com/us/app/ikey-parent/id1476014901'
                    >
                      <img src={appstore} />
                    </a>
                  </li>
                  <li>
                    <a
                      className='download'
                      target='_blank'
                      href='https://appgallery.huawei.com/#/app/C104430057'
                    >
                      <img src={gallery} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
