// firebase.js
import Firebase from 'firebase/app';
import 'firebase/remote-config';

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: 'AIzaSyCrcClX5GAEmH4ro4tdrx22VCBs85kuNWw',
  authDomain: 'ikeyedutech-student.firebaseapp.com',
  databaseURL: 'https://ikeyedutech-student.firebaseio.com',
  projectId: 'ikeyedutech-student',
  storageBucket: 'ikeyedutech-student.appspot.com',
  messagingSenderId: '838670185333',
  appId: '1:838670185333:web:86aeaae5749053c3bdd93c',
};

export const firebase = Firebase.initializeApp(firebaseConfig);
