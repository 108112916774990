import React from 'react';
import '../style/Footer.css';

import icon from '../assets/logo-icon.png';
import word from '../assets/logo-word.png';
import playstore from '../assets/google-play-logo.png';
import appstore from '../assets/app-store-logo.png';
import gallery from '../assets/app-gallery-logo.png';
import educator from '../assets/educator-logo-sm.png';
import parent from '../assets/parent-logo-sm.png';
import kidz from '../assets/kids-logo-sm.png';

export default class Footer extends React.Component {
  constructor() {
    super();
  }

  directToIkey(link) {
    window.open('https://www.ikeyedutech.com.my/' + link);
  }

  render() {
    return (
      <>
        {this.props.type === 1 ? (
          <footer className='page-footer font-mefium pt-4 bg-light'>
            <div className='footer-copyright text-center p-3 link'>
              <a href='#'>
                Powered by iKEY Edutech Sdn. Bhd. © 2018-
                {new Date().getFullYear()}
              </a>
            </div>
          </footer>
        ) : (
          <footer>
            <div className='container'>
              <div className='row upper-footer'>
                <div className='columns col-lg-3 col-md-3 col-sm-4 col-12'>
                  <a className='logo' href='index.html'>
                    <img className='logo-img' src={icon} />
                    <img
                      className='logo-word'
                      src={word}
                      alt='image not found'
                    />
                  </a>
                </div>
                <div className='columns col-lg-9 col-md-9 col-sm-8 col-12 text-right'>
                  <ul className='apps-icon'>
                    <li>
                      <img src={educator} />
                    </li>
                    <li>
                      <img src={kidz} />
                    </li>
                    <li>
                      <img src={parent} />
                    </li>
                  </ul>
                </div>
              </div>
              <div className='row middle-footer menu-row'>
                <div className='columns col-lg-3 col-md-3 col-sm-6 col-12'>
                  <ul>
                    <li className='title-header'>ABOUT iKEY</li>
                    <li>
                      <a onClick={() => this.directToIkey('index.html')}>
                        Home
                      </a>
                    </li>
                    <li>
                      <a onClick={() => this.directToIkey('our-story.html')}>
                        iKEY Story
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => this.directToIkey('meet-the-team.html')}
                      >
                        Meet The Team
                      </a>
                    </li>
                    <li>
                      <a onClick={() => this.directToIkey('announcement.html')}>
                        Announcement
                      </a>
                    </li>
                    <li>
                      <a onClick={() => this.directToIkey('mdec.html')}>
                        SME Digitalization Initiative
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='columns col-lg-3 col-md-3 col-sm-6 col-12'>
                  <ul>
                    <li className='title-header'>PRODUCT</li>
                    <li>
                      <a onClick={() => this.directToIkey('business.html')}>
                        Centre Management System
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => this.directToIkey('education-apps.html')}
                      >
                        iKEY Educator App
                      </a>
                    </li>
                    <li>
                      <a onClick={() => this.directToIkey('parent-apps.html')}>
                        iKEY Parent App
                      </a>
                    </li>
                    <li>
                      <a onClick={() => this.directToIkey('student-apps.html')}>
                        iKEY Student App
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='columns col-lg-3 col-md-3 col-sm-6 col-12'>
                  <ul>
                    <li className='title-header'>HELP</li>
                    <li>
                      <a
                        data-toggle='modal'
                        data-target='#supportModal'
                        href='#'
                      >
                        Contact Us
                      </a>
                    </li>
                    <li>
                      <a onClick={() => this.directToIkey('faq.html')}>FAQ</a>
                    </li>
                    <li>
                      <a onClick={() => this.directToIkey('terms.html')}>
                        Terms
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => this.directToIkey('privacy-policy.html')}
                      >
                        Privacy
                      </a>
                    </li>
                    <li>
                      <a onClick={() => this.directToIkey('training.html')}>
                        Live Webinar Registration
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='columns col-lg-3 col-md-3 col-sm-6 col-12'>
                  <ul>
                    <li className='title-header'>DOWNLOAD APPS</li>
                    <li>
                      <a
                        className='download'
                        target='_blank'
                        href='https://play.google.com/store/apps/details?id=my.com.ikeyedutect.parent.full&hl=en&gl=US'
                      >
                        <img src={playstore} />
                      </a>
                    </li>
                    <li>
                      <a
                        className='download'
                        target='_blank'
                        href='https://apps.apple.com/us/app/ikey-parent/id1476014901'
                      >
                        <img src={appstore} />
                      </a>
                    </li>
                    <li>
                      <a
                        className='download'
                        target='_blank'
                        href='https://appgallery.huawei.com/#/app/C104430057'
                      >
                        <img src={gallery} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='row lower-footer'>
                <div className='columns col-lg-3 col-md-5 col-sm-12 col-12 footer-list-menu'>
                  <ul className='footer-menu'>
                    <li>
                      <a onClick={() => this.directToIkey('terms.html')}>
                        Terms
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => this.directToIkey('privacy-policy.html')}
                      >
                        Privacy
                      </a>
                    </li>
                    <li>
                      <a
                        data-toggle='modal'
                        data-target='#supportModal'
                        href='#'
                      >
                        Contact Us
                      </a>
                    </li>
                    <li>
                      <a onClick={() => this.directToIkey('faq.html')}>FAQ</a>
                    </li>
                    <li className='last'>
                      <a onClick={() => this.directToIkey('sitemap.html')}>
                        Sitemap
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='columns col-lg-7 col-md-5 col-sm-6 col-6'>
                  <ul className='social-media-icon'>
                    <li>
                      <a
                        href='https://www.facebook.com/ikeyeducation/'
                        target='_blank'
                      >
                        <i className='fa fa-facebook-square'></i>
                      </a>
                    </li>
                    <li>
                      <a href='' target='_blank'>
                        <i className='fa fa-instagram-square'></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='columns col-lg-2 col-md-2 col-sm-6 col-6 text-right copyright'>
                  © 2018-{new Date().getFullYear()}
                </div>
              </div>
            </div>
          </footer>
        )}
      </>
    );
  }
}
