import React from 'react';

import '../style/EnrolForm.css';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import axios from 'axios';
import { BASE_URL } from '../constants';
import { lang } from 'moment';
import { format } from 'react-string-format';

export default class EnrolForm3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      referral: '',
      message: '',
      tnc: '',

      formData: '',
      submitted: false,
      modalTnc: false,

      agreeTnc: false,
      agreeCenter: false,
      agreeConsent: false,
      course: [],
    };
  }

  componentDidMount() {}

  isValid() {
    if (!this.state.agreeTnc) {
      return false;
    } else if (this.props.centerData.tnc) {
      if (!this.state.agreeCenter) {
        return false;
      }
    }
    return true;
  }

  onSubmitForm3(event) {
    event.preventDefault();

    this.setState({ submitted: true }, () => {
      if (this.isValid()) {
        let newForm = {
          ...this.props.formData,
          CourseIds: this.state.course,
          Status: false,
          ReferenceSource: this.state.referral,
          AgreeConsentForm: this.state.agreeConsent,
          ChildrenUniqueToken: '00000000-0000-0000-0000-000000000000',
        };

        this.setState({ formData: newForm }, () => {
          this.props.setFormData(newForm);
          console.log('parse -', JSON.stringify(this.props.formData));
          this.postFormData();
        });
      }
    });
  }

  postFormData = () => {
    this.setState({ loading: true }, () => {
      console.log('parse -', JSON.stringify(this.props.formData));
      axios
        .post(BASE_URL + 'Api/SubmitEnrollmentDetail/V6', {
          ...this.props.formData,
        })
        .then((res) => {
          console.log(res.data.data);
          if (res.data.result.success) {
            this.setState({ loading: false }, () => {
              this.props.submitSuccess();
              // if (this.props.centerData.extraEnrollmentFormLink) {
              //   setTimeout(() => {
              //     window.location.href =
              //       this.props.centerData.extraEnrollmentFormLink;
              //   }, 1000);
              // }
            });
          } else {
            setTimeout(() => {
              this.setState({ loading: false }, () => {
                console.log(res.data.result);
              });
            }, 1500);
          }
        })
        .catch((err) => {
          this.setState({ loading: false }, () => {
            console.log('submit form err -', err);
          });
        });
    });
  };

  onCheckCourse(index, isChecked) {
    if (isChecked) {
      this.state.course.push(index);
    } else {
      let idx = this.state.course.findIndex((item) => item === index);
      this.state.course.splice(idx, 1);
    }
  }

  toggleTnc() {
    if (this.props.centerData.useExternalLinkTnc) {
      window.open(this.props.centerData.tncExternalLink, '_blank');
    } else {
      this.setState({ modalTnc: !this.state.modalTnc }, () => {
        console.log('show tnc....');
      });
    }
  }

  render() {
    const { centerData, language } = this.props;
    console.log(centerData.enableConsentForm);
    return (
      <div className='container-fluid' style={{ backgroundColor: '#f2f5fd' }}>
        <section id='enrol-form' className='container'>
          {/* <!-- Modal TnC --> */}
          <Modal
            isOpen={this.state.modalTnc}
            toggle={this.toggleTnc.bind(this)}
          >
            <ModalHeader toggle={this.toggleTnc.bind(this)}>
              Term of Use
            </ModalHeader>
            <ModalBody
              style={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}
            >
              {centerData.tnc}
            </ModalBody>
            <ModalFooter>
              <Button color='secondary' onClick={() => this.toggleTnc()}>
                Close
              </Button>
            </ModalFooter>
          </Modal>

          <div className='container mt-4 mb-2'>
            <div className='row px-5 my-3 justify-content-between align-items-center'>
              <h5>{language.enrollmentForm}</h5>
              <h6 className='text-right'>
                {format(
                  language.page,
                  `${this.props.pageNum + '/' + this.props.pageNum}`
                )}
              </h6>
            </div>
          </div>

          <div className='bg-white shadow p-5'>
            <h3 className='mb-4 text-capitalize'>
              {language.chooseInterestCourse}
            </h3>
            <form method='post' autoComplete='off'>
              <div className='box'>
                <div className='mt-4'>
                  <label className='col-form-label pt-0'>
                    {language.ignoreCourse}
                  </label>

                  {centerData.courseDetails && (
                    <div className='wrapper mt-4'>
                      {centerData.courseDetails.map((item, index) => {
                        return (
                          <div key={index} className='form-check'>
                            <input
                              type='checkbox'
                              className='form-check-input'
                              id={item.id}
                              onChange={(event) =>
                                this.onCheckCourse(
                                  item.id,
                                  event.target.checked
                                )
                              }
                            />
                            <label className='form-check-label' for={item.id}>
                              {item.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
                <div className='mt-3 danger'>{this.state.message}</div>
                <div className='form-group mt-3 mb-3'>
                  <label>{language.hearAboutUs}</label>
                  <input
                    type='text'
                    name='referral'
                    className='form-control form-control-md'
                    placeholder={language.hearAboutPlaceholder}
                    onChange={(event) =>
                      this.setState({ referral: event.target.value })
                    }
                  />
                </div>

                {/* <!-- ikey tnc --> */}
                <div className='form-check mt-3 mb-3'>
                  <input
                    type='checkbox'
                    className={`form-check-input ${
                      this.state.submitted && !this.state.agreeTnc
                        ? 'is-invalid'
                        : null
                    }`}
                    id='ikeyTnc'
                    name='agreeTnc'
                    onChange={(event) =>
                      this.setState({ agreeTnc: event.target.checked })
                    }
                  />
                  <label className='form-check-label' for='ikeyTnc'>
                    {format(language.agreeTo, 'iKEY Edutech Sdn Bhd')}
                    <a
                      href='https://www.ikeyedutech.com.my/terms'
                      className='tncLink'
                      target='_blank'
                    >
                      {' ' + language.termUse}
                    </a>
                    {' ' + language.and + ' '}
                    <a
                      href='https://www.ikeyedutech.com.my/privacy-policy'
                      className='tncLink'
                      target='_blank'
                    >
                      {language.privacyStatement + ' '}
                    </a>
                    {language.ofIkey}
                    <span> *</span>
                  </label>
                  {/* <!-- Validation Error --> */}
                  <div className='invalid-feedback'>
                    <div>{language.agreeTncRequired}</div>
                  </div>
                </div>

                {/* <!-- Center Tnc --> */}
                <div className='form-check mt-3 mb-3'>
                  <input
                    type='checkbox'
                    className={`form-check-input ${
                      this.state.submitted && !this.state.agreeCenter
                        ? 'is-invalid'
                        : null
                    }`}
                    id='tnc'
                    name='agreeCenter'
                    onChange={(event) =>
                      this.setState({ agreeCenter: event.target.checked })
                    }
                  />
                  <label className='form-check-label' for='tnc'>
                    {format(language.agree, centerData.companyName)}
                    {centerData.tnc || centerData.tncExternalLink ? (
                      <a
                        className='tncLink'
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.toggleTnc()}
                      >
                        {' ' + language.termCondition + ' '}
                      </a>
                    ) : (
                      <a
                        className='tncLink'
                        style={{
                          textDecoration: 'none',
                          color: '#000',
                          cursor: 'default',
                        }}
                      >
                        {' ' + language.termCondition + ' '}
                      </a>
                    )}
                    {format(language.of, centerData.companyName)}
                    <span> *</span>
                  </label>
                  {/* <!-- Validation Error --> */}

                  <div className='invalid-feedback'>
                    <div>
                      {format(
                        language.agreeCenterRequired,
                        centerData.companyName
                      )}
                    </div>
                  </div>
                </div>

                {centerData.enableConsentForm && centerData.consentForm ? (
                  <div className='form-check mt-3 mb-3'>
                    <input
                      type='checkbox'
                      className='form-check-input'
                      id='consent'
                      name='agreeConsent'
                      onChange={(event) =>
                        this.setState({ agreeConsent: event.target.checked })
                      }
                    />
                    <label className='form-check-label' for='consent' style={{color:'#1d59df'}}>
                      {centerData.consentForm}
                    </label>
                  </div>
                ) : null}

                {/* Button */}
                <div className='row mt-2 '>
                  <div className='col-md-12 text-center'>
                    <button
                      disabled={this.state.loading}
                      className='btn-prev'
                      type='submit'
                      onClick={() => this.props.backToPage2()}
                    >
                      {language.prev}
                    </button>
                    {!this.state.loading ? (
                      <button
                        className='btn-next'
                        type='submit'
                        onClick={(event) => this.onSubmitForm3(event)}
                      >
                        {language.submit}
                      </button>
                    ) : (
                      <button className='btn-next' disabled>
                        <i className='fa fa-circle-o-notch fa-spin'></i>
                        {language.loading}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
    );
  }
}
