import "../style/EnrolForm.css";

import axios from "axios";
import React from "react";

// import DatePicker from 'reactstrap-date-picker';
import CenterBar from "../components/CenterBar";
import EnrolForm3 from "./EnrolForm3";
import { BASE_URL } from "../constants";
import moment from "moment";
import Footer from "../components/Footer";
import Loader from "react-spinners/PuffLoader";

import EnrolForm2 from "./EnrolForm2";
import { format } from "react-string-format";
import Loading from "../components/Loading";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import EnrolForm4 from "./EnrolForm4";

import appGallery from "../assets/app-gallery-logo.png";
import playStore from "../assets/google-play-logo.png";
import appStore from "../assets/app-store-logo.png";

import Lottie from "react-lottie";
import successJson from "../assets/success.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: successJson,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const emailValidate = /[\w-]+@([\w-]+\.)+[\w-]+/;
export default class EnrolForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // OptionGroup
      centerData: [],
      countryOption: [],
      stadardOption: [],
      stateOption: [],
      dialingOption: [],

      //Value
      name: "",
      othername: "",
      gender: "",
      defaultDate: new Date(),
      dob: new Date(),
      email: "",
      ic: "",
      birth: "",
      school: "",
      grade: "",
      unit: "",
      street: "",
      city: "",
      postal: "",
      country: "",
      province: "",
      medical: "",

      contactDialing: "",
      contactPhone: "",

      // ExtraField
      spokenLanguage: "",
      siblingNo: "",
      position: "",
      race: "",
      religion: "",
      placeBirth: "",
      nation: "",

      // Files Upload
      uploadIndex: 0,
      uploadLoading: false,
      fileDetails: "",
      documentDetails: [],

      // Profile Upload
      profileLoading: false,
      profileDetails: [],
      profileId: 0,

      formData: "",
      submitted: false,
      success: false,
      initial: true,
      modalTnc: false,

      // Show Page
      page1: true,
      page2: false,
      page3: false,
      page4: false,

      activeLanguage: "",
      languageId: "",
      pageNum: "",
    };

    this.language = [
      { id: "en", name: "English" },
      { id: "zh", name: "中文" },
      { id: "my", name: "Malay" },
      { id: "jp", name: "日本語" },
    ];

    this.download = [
      {
        id: "huawei",
        link: "https://appgallery.huawei.com/#/app/C104430057",
        img: appGallery,
      },
      {
        id: "google",
        link: "https://play.google.com/store/apps/details?id=my.com.ikeyedutect.parent.full&hl=en&gl=US",
        img: playStore,
      },
      {
        id: "ios",
        link: "https://apps.apple.com/us/app/ikey-parent/id1476014901",
        img: appStore,
      },
    ];
  }

  componentDidMount() {
    this.props.setLanguage("en");
    if (this.props.formData) {
      this.props.setFormData("");
    }
    this._getCenterData(this.props.match.params.id);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.initial !== this.state.initial) {
      this._getCenterData(this.props.match.params.id);
    }
  }

  resetForm() {
    this.setState({
      name: "",
      gender: "",
      defaultDate: new Date(),
      dob: new Date(),
      ic: "",
      email: "",
      birth: "",
      school: "",
      grade: "",
      unit: "",
      street: "",
      city: "",
      postal: "",
      country: "",
      province: "",
      medical: "",

      contactDialing: "",
      contactPhone: "",

      // ExtraField
      spokenLanguage: "",
      siblingNo: "",
      position: "",
      race: "",
      religion: "",
      placeBirth: "",
      nation: "",

      // Files Upload
      uploadIndex: 0,
      uploadLoading: false,
      fileDetails: "",
      documentDetails: [],

      formData: "",
      submitted: false,
      initial: true,

      // Profile
      profileDetails: [],
      profileId: 0,
    });
  }

  switchLanguage = (id) => {
    let currentLanguage = this.language
      .filter((item) => {
        return item.id === id;
      })
      .map((i) => {
        return i.name;
      });
    console.log(currentLanguage);
    this.setState({ activeLanguage: currentLanguage, languageId: id }, () => {
      this.props.setLanguage(id);
    });
  };

  _getCenterData(id) {
    let guid = id ? id : "19911474-c6c1-420c-821f-639d6348fdb5";
    // console.log('guid', guid)
    axios
      // .get(
      //   'https://ikeyapi-s2.azurewebsites.net//Api/GetEnrollmentInfoV2/' + guid
      // )
      .get(BASE_URL + "Api/GetEnrollmentInfoV2/" + guid)
      .then((res) => {
        if (res.data.result.success) {
          console.log(res.data.data);
          this.props.setCenterData(res.data.data);
          this.setState(
            {
              centerData: res.data.data,
              countryOption: res.data.data.countryDetails,
              stadardOption: res.data.data.studentStandardDetails,
              fileDetails: res.data.data.documentTypeDetails,
              pageNum:
                res.data.data.enrollmentSettingModels.length > 0 ? "4" : "3",
              initial: false,
            },
            () => {
              this.handleDocumentDetails(res.data.data.documentTypeDetails);
            }
          );
        } else {
          // setTimeout(() => {
          //   window.location.href = 'https://www.ikeyedutech.com.my';
          // }, 1000);
        }
      })
      .catch((err) => {
        console.log("Center retrieve error - ", err);
      });
  }

  handleDocumentDetails(data) {
    console.log(data.length);
    let newDoc = [];
    if (data.length > 0) {
      data.map((item) => {
        newDoc.push({
          DocumentTypeId: item.id,
          FileId: 0,
        });
      });

      this.setState({ documentDetails: newDoc }, () => {
        console.log("DocumentDetails -", this.state.documentDetails);
      });
    }
  }

  _getStates(code) {
    let newCty = this.state.countryOption;
    let tempCode = "";
    if (isNaN(code)) {
      tempCode = code;
    } else {
      tempCode = parseInt(code);
    }

    let newCode = newCty
      .filter((item) => {
        return item.id === tempCode;
      })
      .map((i) => {
        return i.twoLettersIsoCode;
      });
    // console.log('newcode', newCode);

    axios
      .get(BASE_URL + "Api/Countries/" + newCode)
      .then((res) => {
        if (res.data.result.success) {
          // console.log(res.data.data);
          this.setState({ stateOption: res.data.data });
        }
      })
      .catch((err) => {
        console.log("states err - ", err);
      });
  }

  // get & Upload File
  async getUploadFiles(event, index) {
    this.setState({ uploadLoading: true, uploadIndex: index + 1 }, async () => {
      let file = event.target.files[0];
      let currentFile = [...this.state.fileDetails];

      const base64 = await this.convertBase64(file);

      let newBase64 = base64.split(",")[1];

      // console.log(newBase64);

      let newFile = {
        ...this.state.fileDetails[index],
        fileName: file.name,
        fileBase64: "",
        fileType: file.type,
        fileSize: this.formatBytes(file.size),
      };

      let uploadFile = {
        FileName: file.name,
        ContentType: file.type,
        FileBase64: newBase64,
      };

      let fileId = this.state.fileDetails[index].id;

      currentFile[index] = newFile;

      this.setState({ fileDetails: currentFile }, () => {
        console.log("File", this.state.fileDetails);
        this._uploadFileToApi(uploadFile, fileId);
      });
    });
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  convertBase64 = (file) => {
    // console.log('filename -', file);

    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  removeFile(index) {
    let currentFile = [...this.state.fileDetails];

    currentFile[index] = {
      ...this.state.fileDetails[index],
      fileName: "",
      fileBase64: "",
      fileType: "",
      fileSize: "",
    };

    this.setState({ fileDetails: currentFile, uploadIndex: 0 }, () => {
      console.log("remove file - ", this.state.fileDetails);
    });
  }

  _uploadFileToApi = (file, id) => {
    // console.log(`uploading File`, file, id);
    // DocumentTypeReturnDeatails: [{ DocumentTypeId, FileId }];
    axios
      .post(BASE_URL + "Api/Enrollment/SubmitDocument/" + id, {
        ...file,
      })
      .then((res) => {
        console.log("up return - ", res.data.data);
        if (res.data.result.success) {
          let newFileIndex = this.state.fileDetails.findIndex((item) => {
            return item.id === id;
          });
          let currentFile = [...this.state.fileDetails];
          let currentDocument = [...this.state.documentDetails];

          let newFileDetail = {
            ...currentFile[newFileIndex],
            fileBase64: res.data.data.fileLocation,
          };

          // console.log(newFileIndex, newFileDetail);
          currentFile[newFileIndex] = newFileDetail;

          let documentIndex = this.state.documentDetails.findIndex((item) => {
            return item.DocumentTypeId === id;
          });

          currentDocument[documentIndex] = {
            ...currentDocument[documentIndex],
            FileId: res.data.data.fileId,
          };

          console.log(documentIndex, currentDocument);
          this.setState(
            {
              fileDetails: currentFile,
              documentDetails: currentDocument,
              index: 0,
              uploadLoading: false,
            },
            () => {
              console.log("sucess -", this.state.documentDetails);
            }
          );
        }
      })
      .catch((err) => {
        this.setState({ uploadLoading: false }, () => {
          console.log("upload File err -", err);
        });
      });
  };

  // Profile Upload
  uploadProfile(event) {
    // console.log(event);
    let profile = event.target.files[0];
    if (profile.type.split("/")[0] === "image") {
      this.setState({ profileLoading: true }, async () => {
        // console.log(profile);

        const base64 = await this.convertBase64(profile);

        let newBase64 = base64.split(",")[1];

        let newProfile = {
          fileName: profile.name,
          fileBase64: "",
          fileType: profile.type,
          fileSize: this.formatBytes(profile.size),
        };

        let uploadProfile = {
          FileName: profile.name,
          ContentType: profile.type,
          FileBase64: newBase64,
        };

        this.setState({ profileDetails: newProfile }, () => {
          this._uploadProfileToApi(uploadProfile);
        });
      });
    } else {
      alert("Please upload image file");
    }
  }

  _uploadProfileToApi(file) {
    console.log(`uploading profile`, file);
    axios
      .post(BASE_URL + "Api/Enrollment/SubmitProfilePicture", {
        ...file,
      })
      .then((res) => {
        console.log("profile return - ", res.data.data);
        if (res.data.result.success) {
          // let currentProfile = [...this.state.profileDetails];

          let currentProfile = {
            ...this.state.profileDetails,
            fileId: res.data.data.fileId,
            fileBase64: res.data.data.fileLocation,
          };

          // console.log(documentIndex, currentDocument);
          this.setState(
            {
              profileDetails: currentProfile,
              profileLoading: false,
              profileId: res.data.data.fileId,
            },
            () => {
              console.log("sucess -", this.state.profileDetails);
            }
          );
        }
      })
      .catch((err) => {
        this.setState({ profileLoading: false }, () => {
          console.log("upload File err -", err);
        });
      });
  }

  isValid() {
    let regex = new RegExp("^\\d{6}\\-\\d{2}\\-\\d{4}$");
    console.log(regex.test(this.state.ic));

    if (this.state.name.length === 0) {
      return false;
    } else if (this.state.gender === "") {
      return false;
    } else if (this.state.dob === "") {
      return false;
    } else if (this.state.contactPhone === "") {
      return false;
    } else if (this.state.ic.length === 0) {
      return false;
    } else if (this.state.unit.length === 0) {
      return false;
    } else if (this.state.street.length === 0) {
      return false;
    } else if (this.state.city.length === 0) {
      return false;
    } else if (this.state.postal.length < 5) {
      return false;
    } else if (this.state.country === "") {
      return false;
    } else if (this.state.province === "") {
      return false;
    }

    return true;
  }

  onFormSubmit = (event) => {
    event.preventDefault();
    console.log("formSubmit ", this.state.documentDetails);

    this.setState({ submitted: true }, () => {
      if (this.isValid()) {
        let newDob = moment(this.state.dob).toDate();
        // console.log('dob', moment(newDob).format('yyyy-MM-DD'));
        let newForm = {
          CenterUserId: this.props.centerData.centerUserId,
          StudentName: this.state.name,
          OtherName: this.state.othername,
          DateOfBirth: this.state.dob,
          StudentEmail: this.state.email,
          DialingCode: this.state.contactDialing,
          PhoneNumber: this.state.contactPhone,
          GenderId: this.state.gender,
          BirthCert: this.state.birth,
          IdentityCard: this.state.ic,
          Nationality: this.state.nation,
          SpokenLanguage: this.state.spokenLanguage,
          SiblingNo: this.state.siblingNo,
          PositionInFamily: this.state.position,
          Religion: this.state.religion,
          Race: this.state.race,
          PlaceOfBirth: this.state.placeBirth,
          SchoolName: this.state.school,
          StandardId: this.state.grade,
          Address1: this.state.unit,
          Address2: this.state.street,
          City: this.state.city,
          PostCode: this.state.postal,
          CountryId: this.state.country,
          StateProvinceId: this.state.province,
          MedicalCondition: this.state.medical,
          DocumentTypeReturnDetails: this.state.documentDetails,
          EnrollmentSettingModels:
            this.state.centerData.enrollmentSettingModels,
          ProfileImageId: this.state.profileId,
        };

        console.log("newForm -", newForm);

        this.setState({ page2: true, formData: newForm }, () => {
          this.props.setFormData(newForm);
          this.setState({ page1: false }, () => {
            document.getElementById("enrol-form-2").scrollIntoView();
          });
        });
      } else {
        document.getElementById("enrol-form").scrollIntoView();
      }
    });
  };

  scrollToPage3() {
    if (this.state.centerData.enrollmentSettingModels.length > 0) {
      this.setState({ page4: true }, () => {
        this.setState({ page2: false }, () => {
          document.getElementById("enrol-form-4").scrollIntoView();
        });
      });
    } else {
      this.setState({ page3: true }, () => {
        this.setState({ page2: false }, () => {
          document.getElementById("enrol-form-done").scrollIntoView();
        });
      });
    }
  }

  scrollToPage4() {
    this.setState({ page3: true }, () => {
      this.setState({ page4: false }, () => {
        document.getElementById("enrol-form-done").scrollIntoView();
      });
    });
  }

  submitSuccess = () => {
    if (this.props.centerData.extraEnrollmentFormLink) {
      alert(this.props.language.anotherPage);
      window.location.href = this.props.centerData.extraEnrollmentFormLink;
    } else {
      this.setState({ success: true, page1: true }, () => {
        // window.location.reload();
        document.getElementById("enrol-form").scrollIntoView();
      });
    }
  };

  newSubmission = () => {
    this.setState(
      { success: false, page2: false, page3: false, page1: true },
      () => {
        this.resetForm();
        this.props.setFormData("");
        localStorage.clear();
        document.getElementById("enrol-form").scrollIntoView();
      }
    );
  };

  toggleTnc() {
    this.setState({ modalTnc: !this.state.modalTnc }, () => {
      console.log("show tnc....");
    });
  }

  backToPage1() {
    this.setState({ page2: false, page1: true }, () => {
      document.getElementById("enrol-form").scrollIntoView();
    });
  }

  backToPage2() {
    console.log(this.state.centerData.enrollmentSettingModels.length);
    if (this.state.centerData.enrollmentSettingModels.length > 0) {
      if (this.state.page3) {
        console.log("bck 4 frm 3");
        this.setState({ page3: false, page4: true }, () => {
          document.getElementById("enrol-form-4").scrollIntoView();
        });
      } else if (this.state.page4) {
        console.log("bck 3 frm 4");
        this.setState({ page4: false, page2: true }, () => {
          document.getElementById("enrol-form-2").scrollIntoView();
        });
      }
    } else {
      console.log("back 2 frm 3");
      this.setState({ page3: false, page2: true }, () => {
        document.getElementById("enrol-form-2").scrollIntoView();
      });
    }
  }

  render() {
    const { countryOption } = this.state;
    const { language, noCenterBar, centerData } = this.props;
    // console.log(centerData.tnc.includes('\n'));
    return (
      <div>
        {/* <NavigationBar {...this.props} /> */}
        {this.state.initial ? (
          <Loading />
        ) : (
          <div
            id="enrol-head"
            className="container-fluid"
            style={{ backgroundColor: "#f2f5fd" }}
          >
            {/* <Button color='secondary' onClick={() => this.toggleTnc()}>
              Close
            </Button> */}
            {/* <!-- Modal TnC --> */}
            {/* <Modal
              isOpen={false}
              toggle={this.toggleTnc.bind(this)}
              // className={this.props.className}
            >
              <ModalHeader toggle={this.toggleTnc.bind(this)}>
                Term of Use
              </ModalHeader>
              <ModalBody style={{ whiteSpace: 'pre-line' }}>
                {centerData.tnc}
              </ModalBody>
              <ModalFooter>
                <Button color='secondary' onClick={() => this.toggleTnc()}>
                  Close
                </Button>
              </ModalFooter>
            </Modal> */}
            {/* <Alert color='danger'>This is a danger alert — check it out!</Alert> */}
            {!noCenterBar && (
              <div className="container text-right pt-3">
                {this.language.map((item, index) => {
                  return (
                    <button
                      key={index}
                      className={`${
                        index !== this.language.length ? "mr-2" : null
                      }`}
                      style={{
                        backgroundColor:
                          this.state.activeLanguage.toString() !== item.name
                            ? "#ec7993"
                            : "#e01f4b",
                        color: "#fff",
                        borderWidth: 0,
                        padding: 5,
                        borderRadius: 5,
                        width: 75,
                      }}
                      onClick={() => this.switchLanguage(item.id)}
                    >
                      {item.name}
                    </button>
                  );
                })}
              </div>
            )}
            {!noCenterBar && (
              <CenterBar center={this.state.centerData} {...this.props} />
            )}
            {this.state.page1 && (
              <section id="enrol-form" className="container">
                {/* <LanguageBar {...this.props} /> */}

                <div className="container mt-4 mb-2" style={{ zIndex: -1 }}>
                  <div className="row px-5 my-3 justify-content-between align-items-center">
                    <h5>{language.enrollmentForm}</h5>
                    {!this.state.success && (
                      <h6 className="text-right">
                        {format(language.page, `${"1/" + this.state.pageNum}`)}
                      </h6>
                    )}
                  </div>
                </div>

                {this.state.success ? (
                  <div className="bg-white shadow p-5 mb-5">
                    <div className="text-center">
                      {/* <i
                        className='fa fa-check-circle my-3'
                        style={{ color: 'green', fontSize: 50 }}
                      ></i> */}
                      <Lottie
                        options={defaultOptions}
                        height={150}
                        width={150}
                      />
                      <p style={{ fontSize: 20 }}>{language.submittedForm}</p>
                      <a
                        className="btn btn-outline-primary mr-3"
                        style={{
                          padding: 10,
                          fontSize: 15,
                        }}
                        onClick={() => this.newSubmission()}
                      >
                        {language.newSubmission}
                      </a>

                      <a
                        href="https://www.ikeyedutech.com.my"
                        className="btn btn-outline-secondary ml-3"
                        style={{
                          width: 100,
                          padding: 10,
                          fontSize: 15,
                        }}
                      >
                        {language.visitUs}
                      </a>
                    </div>
                    <div className="col-12 px-3 text-center">
                      <p className="text-center mt-5 h5">
                        Download iKEY app on
                      </p>
                      <div className="row mt-3">
                        {this.download.map((item, index) => {
                          return (
                            <div
                              className="col-md-4"
                              style={{ cursor: "pointer" }}
                              onClick={() => window.open(item.link)}
                            >
                              <img
                                key={index}
                                src={item.img}
                                style={{ width: "70%" }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="bg-white shadow p-5">
                      <h3 className="mb-4 text-capitalize">
                        {language.learnerInfo}
                      </h3>
                      <form autoComplete="off">
                        <div className="box">
                          <div className="fileupload mt-1" id="container-file">
                            <label className="mt-4">{language.profile}</label>
                            <span
                              className={`btn btn-default fileinput-button`}
                            >
                              <div className="text-center">
                                <span
                                  className="outline-circle"
                                  style={{
                                    display: "inline-block",
                                  }}
                                >
                                  <i className="fa fa-arrow-up border-circle" />
                                </span>
                              </div>
                              <i
                                className={`${
                                  this.state.profileLoading
                                    ? "loading"
                                    : "upload"
                                }`}
                              ></i>

                              {!this.state.profileLoading && (
                                <input
                                  className="fileUploadMobile"
                                  type="file"
                                  name="files"
                                  id="file"
                                  data-browse-on-zone-click="true"
                                  onChange={(event) =>
                                    this.uploadProfile(event)
                                  }
                                  disabled={this.state.profileLoading}
                                />
                              )}
                            </span>
                          </div>
                          <br />
                          {this.state.profileDetails.fileName ? (
                            <div className="preview-frame4 mt-2">
                              <div
                                onClick={() =>
                                  this.setState({ profileDetails: [] })
                                }
                              >
                                <i className="fa fa-remove remove-icon"></i>
                              </div>
                              {this.state.profileLoading ? (
                                <Loader
                                  css={`
                                    display: block;
                                    margin: 0 auto;
                                    border-color: red;
                                  `}
                                  className="mt-2"
                                  size={75}
                                  color={"#123abc"}
                                  loading={true}
                                />
                              ) : (
                                <img
                                  src={this.state.profileDetails.fileBase64}
                                  className="text-center mt-1 mb-1 mr-0 p-0 img-logo"
                                  alt="images.png"
                                />
                              )}
                              <div className="file-caption">
                                <div className="file-name">
                                  {this.state.profileDetails.fileName}
                                </div>
                                <div className="file-info">
                                  <samp>
                                    {this.state.profileDetails.fileSize}
                                  </samp>
                                </div>
                              </div>

                              <br />
                            </div>
                          ) : null}

                          {/* <!-- Name + Gender--> */}
                          <div className="row">
                            {/* Name */}
                            <div className="form-group col-md-6">
                              <label className="col-form-label pt-0">
                                {language.name} <span>*</span>
                                {/* (As per NRIC) */}
                              </label>
                              <input
                                type="text"
                                name="name"
                                className={`form-control form-control-md ${
                                  this.state.submitted &&
                                  !this.state.name.length > 0
                                    ? "is-invalid"
                                    : null
                                }`}
                                placeholder={language.enterName}
                                value={this.state.name}
                                required
                                onChange={(event) =>
                                  this.setState({ name: event.target.value })
                                }
                              />
                              {/* <!-- Validation Error --> */}
                              <div className="invalid-feedback">
                                <div>{language.nameRequired}</div>
                              </div>
                            </div>
                            {/* Other Name */}
                            <div className="form-group col-md-6">
                              <label className="col-form-label pt-0">
                                {language.otherName}
                                {/* (As per NRIC) */}
                              </label>
                              <input
                                type="text"
                                name="name"
                                className={`form-control form-control-md `}
                                placeholder={language.enterOtherName}
                                value={this.state.othername}
                                required
                                onChange={(event) =>
                                  this.setState({
                                    othername: event.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>

                          <div className="row">
                            {/* Gender */}
                            <div className="form-group col-md-6">
                              <label className="col-form-label pt-0">
                                {language.gender} <span>*</span>
                              </label>
                              <select
                                className={`form-control custom-select ${
                                  this.state.submitted &&
                                  this.state.gender === ""
                                    ? "is-invalid"
                                    : null
                                }`}
                                value={this.state.gender}
                                onChange={(event) =>
                                  this.setState({ gender: event.target.value })
                                }
                              >
                                <option value="" disabled>
                                  {language.chooseGender}
                                </option>
                                <option value="10">{language.male}</option>
                                <option value="20">{language.female}</option>
                                {/* <option value='30'>Not Specify</option> */}
                              </select>

                              {/* <!-- Validation Error --> */}
                              <div className="invalid-feedback">
                                <div>{language.chooseGender}</div>
                              </div>
                            </div>

                            <div className="form-group col-md-6">
                              <label className="col-form-label pt-0">
                                {/* <span>*</span> */}
                                {language.email}
                              </label>
                              <input
                                type="email"
                                name="email"
                                className={`form-control form-control-md ${
                                  this.state.submitted &&
                                  this.state.email.length > 0 &&
                                  !emailValidate.test(this.state.email)
                                    ? "is-invalid"
                                    : null
                                }`}
                                placeholder={language.enterEmail}
                                required
                                value={this.state.email}
                                onChange={(event) => {
                                  this.setState({ email: event.target.value });
                                }}
                              />
                              {/* <!-- Validation Error --> */}
                              <div className="invalid-feedback">
                                {this.state.email.length > 0 &&
                                  !emailValidate.test(this.state.email) && (
                                    <div>{language.emailIncorrect}</div>
                                  )}
                              </div>
                            </div>
                          </div>

                          {/* // <!-- Dialing Code + Contact --> */}
                          <label className="contact-label">
                            {language.contactNumber}
                            <span>*</span>
                          </label>
                          <div className="row">
                            <div className="form-group col-md-3">
                              <select
                                defaultValue=""
                                value={this.state.contact1Dialing}
                                className={`form-control custom-select ${
                                  this.state.submitted &&
                                  this.state.contactDialing === ""
                                    ? "is-invalid"
                                    : null
                                }`}
                                value={this.state.contactDialing}
                                onChange={(event) =>
                                  this.setState({
                                    contactDialing: event.target.value,
                                  })
                                }
                              >
                                <option value="" disabled>
                                  {language.selectDialingCode}
                                </option>
                                {countryOption.map((item, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={item.dialingCode}
                                    >
                                      {item.dialingCode}
                                    </option>
                                  );
                                })}
                              </select>
                              {/* // <!-- Validation Error --> */}
                              <div className="invalid-feedback">
                                <div>{language.selectDialingCode}</div>
                              </div>
                            </div>
                            <div className="form-group col-md-9">
                              {/* <!-- <label className="col-form-label pt-0">Contact</label> --> */}
                              <input
                                type="tel"
                                name="contact"
                                className={`form-control form-control-md ${
                                  this.state.submitted &&
                                  this.state.contactPhone.length === 0
                                    ? "is-invalid"
                                    : null
                                }`}
                                placeholder={language.enterContact}
                                required
                                value={this.state.contactPhone}
                                onChange={(event) =>
                                  this.setState({
                                    contactPhone: event.target.value,
                                  })
                                }
                              />
                              {/* <!-- Validation Error --> */}
                              <div className="invalid-feedback">
                                <div>{language.contactNumberRequired}</div>
                              </div>
                            </div>
                          </div>

                          {/* <!-- IC + Birth Cert --> */}
                          <div className="row">
                            <div className="form-group col-md-6">
                              <label>
                                {language.dob}
                                <span>*</span>
                              </label>
                              <div className="input-group mb-2">
                                <DatePicker
                                  selected={this.state.defaultDate}
                                  style={{ width: "100%", borderRadius: 25 }}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  id="exampleDate"
                                  placeholder={language.selectDob}
                                  maxDate={new Date()}
                                  defaultValue={new Date()}
                                  // value={new }
                                  dateFormat={"dd/MM/yyyy"}
                                  onChange={(date) =>
                                    this.setState(
                                      {
                                        dob: moment(new Date(date)).format(
                                          "yyyy-MM-DD"
                                        ),
                                        defaultDate: date,
                                      },
                                      () => {
                                        console.log(
                                          "dob -",
                                          date,
                                          "\nMoment -",
                                          moment(new Date(date)).format(
                                            "yyyy-MM-DD"
                                          )
                                        );
                                      }
                                    )
                                  }
                                  required
                                />
                              </div>

                              {/* <!-- Validation Error --> */}
                              <div className="invalid-feedback">
                                <div>{language.dobRequired}</div>
                              </div>
                            </div>

                            <div className="form-group col-md-6">
                              <label>
                                {language.ic} <span>*</span>
                              </label>
                              <input
                                type="text"
                                name="identityCard"
                                className={`form-control form-control-md ${
                                  this.state.submitted &&
                                  this.state.ic.length === 0
                                    ? "is-invalid"
                                    : null
                                }`}
                                // maxLength={12}
                                placeholder={language.enterIc}
                                value={this.state.ic}
                                required
                                onChange={(event) =>
                                  this.setState({ ic: event.target.value })
                                }
                              />

                              {/* <!-- Validation Error --> */}
                              <div className="invalid-feedback">
                                <div>{language.icRequired}</div>
                              </div>
                            </div>
                          </div>

                          {/* Birth Cert */}
                          <div className="form-group">
                            <label>{language.birthCert}</label>
                            <input
                              type="text"
                              name="birthCert"
                              className={`form-control form-control-md`}
                              placeholder={language.enterbirthCert}
                              value={this.state.birth}
                              onChange={(event) =>
                                this.setState({ birth: event.target.value })
                              }
                            />
                          </div>

                          {/* Extra Field */}
                          {centerData.enableExtendedField && (
                            <>
                              {/* Place Birth */}
                              <div className="form-group">
                                <label>{language.placeBirth}</label>
                                <input
                                  type="text"
                                  name="placeBirth"
                                  className={`form-control form-control-md`}
                                  placeholder={language.enterPlaceBirth}
                                  value={this.state.placeBirth}
                                  onChange={(event) =>
                                    this.setState({
                                      placeBirth: event.target.value,
                                    })
                                  }
                                />
                              </div>
                              {/* Sibling No. + Position */}
                              <div className="row">
                                <div className="form-group col-md-6">
                                  <label className="col-form-label pt-0">
                                    {language.siblingNo}
                                  </label>
                                  <input
                                    type="text"
                                    name="siblingNo"
                                    className={`form-control form-control-md `}
                                    placeholder={language.enterSibling}
                                    value={this.state.siblingNo}
                                    onChange={(event) =>
                                      this.setState({
                                        siblingNo: event.target.value,
                                      })
                                    }
                                  />
                                </div>

                                <div className="form-group col-md-6">
                                  <label className="col-form-label pt-0">
                                    {language.position}
                                  </label>
                                  <input
                                    type="text"
                                    name="position"
                                    className={`form-control form-control-md `}
                                    placeholder={language.enterPosition}
                                    value={this.state.position}
                                    onChange={(event) =>
                                      this.setState({
                                        position: event.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>

                              {/* Spoken Language + Race */}
                              <div className="row">
                                <div className="form-group col-md-6">
                                  <label className="col-form-label pt-0">
                                    {language.spoken}
                                  </label>
                                  <input
                                    type="text"
                                    name="spokenLanguage"
                                    className={`form-control form-control-md `}
                                    placeholder={language.enterSpoken}
                                    value={this.state.spokenLanguage}
                                    onChange={(event) =>
                                      this.setState({
                                        spokenLanguage: event.target.value,
                                      })
                                    }
                                  />
                                </div>

                                <div className="form-group col-md-6">
                                  <label className="col-form-label pt-0">
                                    {language.race}
                                  </label>
                                  <input
                                    type="text"
                                    name="race"
                                    className={`form-control form-control-md `}
                                    placeholder={language.enterRace}
                                    value={this.state.race}
                                    onChange={(event) =>
                                      this.setState({
                                        race: event.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>

                              {/* Religion + Nationality */}
                              <div className="row">
                                <div className="form-group col-md-6">
                                  <label className="col-form-label pt-0">
                                    {language.religion}
                                  </label>
                                  <input
                                    type="text"
                                    name="religion"
                                    className={`form-control form-control-md `}
                                    placeholder={language.enterReligion}
                                    value={this.state.religion}
                                    onChange={(event) =>
                                      this.setState({
                                        religion: event.target.value,
                                      })
                                    }
                                  />
                                </div>

                                <div className="form-group col-md-6">
                                  <label className="col-form-label pt-0">
                                    {language.nation}
                                  </label>
                                  <input
                                    type="text"
                                    name="nation"
                                    className={`form-control form-control-md `}
                                    placeholder={language.enterNation}
                                    value={this.state.nation}
                                    onChange={(event) =>
                                      this.setState({
                                        nation: event.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          )}

                          {/* <!-- School Name + Standar/Form --> */}
                          <div className="row">
                            {!centerData.disableSchoolName && (
                              <div className="form-group col-md-6">
                                <label>{language.schoolName}</label>
                                <input
                                  type="text"
                                  name="schoolName"
                                  className={`form-control form-control-md`}
                                  placeholder={language.enterSchoolName}
                                  value={this.state.school}
                                  onChange={(event) =>
                                    this.setState({
                                      school: event.target.value,
                                    })
                                  }
                                />
                              </div>
                            )}
                            {!centerData.disableStandard && (
                              <div className="form-group col-md-6">
                                <label className="col-form-label pt-0">
                                  {language.standard}
                                </label>
                                <select
                                  className={`form-control custom-select `}
                                  defaultValue=""
                                  placeholder={language.selectStandard}
                                  value={this.state.grade}
                                  onChange={(event) =>
                                    this.setState({ grade: event.target.value })
                                  }
                                >
                                  <option value="" disabled>
                                    {language.selectStandard}
                                  </option>
                                  {this.state.stadardOption.map(
                                    (item, index) => {
                                      return (
                                        <option key={index} value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              </div>
                            )}
                          </div>

                          {/* <!-- Address 1 + 2 --> */}
                          <div className="form-group">
                            <label className="col-form-label pt-0">
                              {language.unit}
                              <span>*</span>
                            </label>
                            <input
                              type="text"
                              name="address1"
                              className={`form-control form-control-md ${
                                this.state.submitted &&
                                this.state.unit.length === 0
                                  ? "is-invalid"
                                  : null
                              }`}
                              placeholder={language.enterUnit}
                              required
                              value={this.state.unit}
                              onChange={(event) =>
                                this.setState({ unit: event.target.value })
                              }
                            />
                            {/* <!-- Validation Error --> */}
                            <div className="invalid-feedback">
                              <div>{language.enterUnit}</div>
                            </div>
                          </div>

                          <div className="form-group">
                            <label>
                              {language.streetName} <span>*</span>
                            </label>
                            <input
                              type="text"
                              name="address2"
                              className={`form-control form-control-md ${
                                this.state.submitted &&
                                this.state.street.length === 0
                                  ? "is-invalid"
                                  : null
                              }`}
                              placeholder={language.enterStreet}
                              required
                              value={this.state.street}
                              onChange={(event) =>
                                this.setState({ street: event.target.value })
                              }
                            />
                            {/* <!-- Validation Error --> */}
                            <div className="invalid-feedback">
                              <div>{language.enterStreet}</div>
                            </div>
                          </div>

                          {/* <!-- City + Postal --> */}
                          <div className="row">
                            <div className="form-group col-md-6">
                              <label className="col-form-label pt-0">
                                {language.city}
                                <span>*</span>
                              </label>
                              <input
                                type="text"
                                name="city"
                                className={`form-control form-control-md ${
                                  this.state.submitted &&
                                  this.state.city.length === 0
                                    ? "is-invalid"
                                    : null
                                }`}
                                placeholder={language.enterCity}
                                value={this.state.city}
                                onChange={(event) =>
                                  this.setState({ city: event.target.value })
                                }
                                required
                              />
                              {/* <!-- Validation Error --> */}
                              <div className="invalid-feedback">
                                <div>{language.enterCity}</div>
                              </div>
                            </div>
                            <div className="form-group col-md-6">
                              <label className="col-form-label pt-0">
                                {language.postalCode}
                                <span>*</span>
                              </label>
                              <input
                                name="postalCode"
                                className={`form-control form-control-md ${
                                  this.state.submitted &&
                                  this.state.postal.length < 5
                                    ? "is-invalid"
                                    : null
                                }`}
                                placeholder={language.enterPostal}
                                type="numeric"
                                maxLength={5}
                                min="10000"
                                max="99999"
                                required
                                value={this.state.postal}
                                onChange={(event) =>
                                  this.setState({ postal: event.target.value })
                                }
                              />
                              {/* <!-- Validation Error --> */}
                              <div className="invalid-feedback">
                                <div>{language.enterPostal}</div>
                              </div>
                            </div>
                          </div>

                          {/* <!-- State + Country --> */}
                          <div className="row">
                            <div className="form-group col-md-6">
                              <label>
                                {language.country}
                                <span>*</span>
                              </label>
                              <select
                                className={`form-control custom-select ${
                                  this.state.submitted &&
                                  this.state.country === ""
                                    ? "is-invalid"
                                    : null
                                }`}
                                defaultValue=""
                                value={this.state.country}
                                onChange={(event) =>
                                  this.setState(
                                    { country: event.target.value },
                                    () => {
                                      this._getStates(event.target.value);
                                    }
                                  )
                                }
                              >
                                <option value="" disabled>
                                  {language.selectCountry}
                                </option>
                                {this.state.countryOption.map((item, index) => {
                                  return (
                                    <option key={index} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </select>

                              {/* <!-- Validation Error --> */}
                              <div className="invalid-feedback no-icon">
                                <div>{language.selectCountry}</div>
                              </div>
                            </div>
                            <div className="form-group col-md-6">
                              <label>
                                {language.state} <span>*</span>
                              </label>
                              <select
                                className={`form-control custom-select ${
                                  this.state.submitted &&
                                  this.state.province === ""
                                    ? "is-invalid"
                                    : null
                                }`}
                                defaultValue=""
                                value={this.state.province}
                                onChange={(event) =>
                                  this.setState({
                                    province: event.target.value,
                                  })
                                }
                              >
                                <option value="" disabled>
                                  {language.selectState}
                                </option>
                                {this.state.stateOption.map((item, index) => {
                                  return (
                                    <option key={index} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </select>
                              {/* <!-- Validation Error --> */}
                              <div className="invalid-feedback">
                                <div>{language.selectState}</div>
                              </div>
                            </div>
                            <div className="form-group col-md-12 mt-2">
                              <label className="col-form-label pt-0">
                                {language.medicalCondition}
                              </label>
                              <p>{language.childSpecialInfo}</p>
                              <input
                                type="text"
                                name="medical"
                                className={`form-control form-control-md `}
                                placeholder={language.medicalPlaceholder}
                                value={this.state.medical}
                                onChange={(event) =>
                                  this.setState({
                                    medical: event.target.value,
                                  })
                                }
                              />
                            </div>
                            {/* <div className='preview-frame ml-3'>
                          
                          <div className='file-pdf text-center'>
                            <i className='fa fa-file-pdf-o text-center pdf-icon'></i>
                          </div>
                          <div className='file-caption'>
                            <div className='file-name'>image name</div>
                            <div className='file-info'>
                              <samp>1024 kb</samp>
                            </div>
                          </div>
                          <div onClick={() => console.log('remove file')}>
                            <i className='fa fa-remove remove-icon'></i>
                          </div>
                        </div> */}

                            {centerData.enableStudentAttachment &&
                              this.state.fileDetails.length > 0 && (
                                <>
                                  {this.state.fileDetails.map((item, index) => {
                                    return (
                                      <>
                                        <div
                                          key={index}
                                          className="fileupload col-md-12 mt-2"
                                          id="container-file"
                                        >
                                          <label className=" pt-0">
                                            {item.name}
                                          </label>
                                          <span
                                            className={`btn btn-default fileinput-button`}
                                          >
                                            <div className="text-center">
                                              <span
                                                className="outline-circle"
                                                style={{
                                                  display: "inline-block",
                                                }}
                                              >
                                                <i className="fa fa-arrow-up border-circle" />
                                              </span>
                                            </div>

                                            <i
                                              className={`${
                                                this.state.uploadIndex ===
                                                  index + 1 &&
                                                this.state.uploadLoading
                                                  ? "loading"
                                                  : "upload"
                                              }`}
                                            />

                                            {!this.state.uploadLoading && (
                                              <input
                                                className="fileUploadMobile"
                                                type="file"
                                                name="files"
                                                id="file"
                                                data-browse-on-zone-click="true"
                                                onChange={(event) =>
                                                  this.getUploadFiles(
                                                    event,
                                                    index
                                                  )
                                                }
                                                disabled={
                                                  this.state.uploadLoading
                                                }
                                              />
                                            )}
                                          </span>
                                        </div>
                                        {item.fileName ||
                                        this.state.uploadIndex === index + 1 ? (
                                          <div className="preview-frame ml-3">
                                            {this.state.uploadLoading &&
                                            this.state.uploadIndex ===
                                              index + 1 ? (
                                              <Loader
                                                css={`
                                                  display: block;
                                                  margin: 0 auto;
                                                  border-color: red;
                                                `}
                                                className="mt-2"
                                                size={75}
                                                color={"#123abc"}
                                                loading={true}
                                              />
                                            ) : (
                                              <>
                                                {item.fileType ===
                                                "application/pdf" ? (
                                                  <div className="file-pdf text-center">
                                                    <i className="fa fa-file-pdf-o text-center pdf-icon"></i>
                                                  </div>
                                                ) : (
                                                  <img
                                                    src={item.fileBase64}
                                                    className="text-center mt-1 mb-1 mr-0 p-0 img-logo"
                                                    alt="images.png"
                                                  />
                                                )}
                                              </>
                                            )}
                                            <div className="file-caption">
                                              <div className="file-name">
                                                {item.fileName}
                                              </div>
                                              <div className="file-info">
                                                <samp>{item.fileSize}</samp>
                                              </div>
                                            </div>
                                            <div
                                              onClick={() =>
                                                this.removeFile(index)
                                              }
                                            >
                                              <i className="fa fa-remove remove-icon"></i>
                                            </div>
                                          </div>
                                        ) : null}
                                      </>
                                    );
                                  })}
                                </>
                              )}
                          </div>

                          <div className="row mt-4">
                            <div className="col-md-12 text-center ">
                              <button
                                className="btn-next"
                                type="submit"
                                onClick={(event) => this.onFormSubmit(event)}
                              >
                                {language.next}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </>
                )}
              </section>
            )}
            {!this.state.success && (
              <>
                <section id="enrol-form-2">
                  {this.state.page2 && (
                    <EnrolForm2
                      countryOption={this.state.countryOption}
                      scrollToPage3={this.scrollToPage3.bind(this)}
                      backToPage1={this.backToPage1.bind(this)}
                      pageNum={this.state.pageNum}
                      contact={[
                        this.state.contactDialing,
                        this.state.contactPhone,
                      ]}
                      {...this.props}
                    />
                  )}
                </section>
                <section id="enrol-form-4">
                  {this.state.page4 && (
                    <EnrolForm4
                      data={this.state.centerData.enrollmentSettingModels}
                      pageNum={this.state.pageNum}
                      scrollToPage4={this.scrollToPage4.bind(this)}
                      backToPage2={this.backToPage2.bind(this)}
                      {...this.props}
                    />
                  )}
                </section>
                <section id="enrol-form-done">
                  {this.state.page3 && (
                    <EnrolForm3
                      submitSuccess={this.submitSuccess.bind(this)}
                      pageNum={this.state.pageNum}
                      backToPage2={this.backToPage2.bind(this)}
                      {...this.props}
                    />
                  )}
                </section>
              </>
            )}
            <br />
            {!noCenterBar ? <Footer type={1} /> : <br />}
          </div>
        )}
      </div>
    );
  }
}
