import React from "react";

import "../style/EnrolForm.css";
import "../style/EnrolForm4.css";
import moment from "moment";
import { format } from "react-string-format";
import Loader from "react-spinners/PuffLoader";
import { BASE_URL } from "../constants";
import axios from "axios";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";

const emailValidate = /[\w-]+@([\w-]+\.)+[\w-]+/;

export default class EnrolForm4 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      question: this.props.data,
      backupQuestion: this.props.data,

      checkedIndex: "",
      radioIndex: "",
      defaultDate: new Date(),

      // Files Upload
      uploadIndex: 0,
      uploadLoading: false,
      fileDetails: [],
      // documentDetails: [],
      checkRes: [],
    };
  }

  componentDidMount() {
    this.initQuestion();
  }

  initQuestion() {
    let currentQuestion = [...this.state.question];

    let date = moment(new Date()).format("yyyy-MM-DD");
    currentQuestion.map((item, index) => {
      if (item.typeId === 8) {
        currentQuestion[index] = {
          ...currentQuestion[index],
          response: date,
        };

        this.setState({
          question: currentQuestion,
          backupQuestion: currentQuestion,
        });
      }
    });
  }

  // get & Upload File
  async getUploadFiles(event, id) {
    // console.log('questionid - ', id);
    this.setState({ uploadLoading: true, uploadIndex: id }, async () => {
      let file = event.target.files[0];

      const base64 = await this.convertBase64(file);

      let newBase64 = base64.split(",")[1];

      // console.log(newBase64);

      this.state.fileDetails.push({
        fileId: id,
        fileName: file.name,
        fileBase64: newBase64,
        fileType: file.type,
        fileSize: this.formatBytes(file.size),
      });

      let uploadFile = {
        FileName: file.name,
        ContentType: file.type,
        FileBase64: newBase64,
      };

      // let fileId = this.state.fileDetails[index].id;

      // currentFile[index] = newFile;

      // this.setState({ fileDetails: newFile }, () => {
      // console.log('File', this.state.fileDetails.fileBase64);
      this._uploadFileToApi(uploadFile, id);
      // });
    });
  }

  removeFile(id, index) {
    let currentFile = [...this.state.fileDetails];
    let currentQuestion = [...this.state.question];

    let currentIdx = currentFile.findIndex((i) => {
      return i.fileId === id;
    });

    // Remove FileDetail
    currentFile.splice(currentIdx, 1);

    // Remove from response
    currentQuestion[index] = {
      ...currentQuestion[index],
      response: null,
    };

    this.setState(
      { fileDetails: currentFile, question: currentQuestion },
      () => {
        console.log("remove file - ", this.state.question);
      }
    );
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  convertBase64 = (file) => {
    // console.log('filename -', file);

    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  validRequired(item) {
    if (this.state.submitted && item.isRequired) {
      let currentQuestion = [...this.state.question];
      let index = this.state.question.findIndex((i) => {
        return i.id === item.id;
      });

      let res = currentQuestion[index].response;
      console.log("validRes - ", res);
      if (res === null) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isValid() {
    let currentQuestion = [...this.state.question];
    // console.log(currentQuestion);
    let valid = false;
    let checkArray = [];
    currentQuestion
      .filter((item) => {
        return item.typeId !== 1;
      })
      .map((item, index) => {
        console.log(item.isRequired, item.response);
        if (item.isRequired && item.response !== null) {
          checkArray.push(true);
        } else if (!item.isRequired) {
          checkArray.push(true);
        } else {
          checkArray.push(false);
        }
      });

    console.log("isValid - ", checkArray);
    if (checkArray.includes(false)) {
      valid = false;
    } else {
      valid = true;
    }

    return valid;
  }

  _uploadFileToApi = (file, id) => {
    console.log(id, `uploading File....`, file);
    // DocumentTypeReturnDeatails: [{ DocumentTypeId, FileId }];
    axios
      .post(BASE_URL + "Api/Enrollment/SubmitQuestionDocument/" + id, {
        ...file,
      })
      .then((res) => {
        console.log("up return - ", res.data);
        if (res.data.result.success) {
          let currentData = res.data.data;
          let currentQuestion = [...this.state.question];
          let currentFile = [...this.state.fileDetails];

          let index = this.state.question.findIndex((i) => {
            return i.id === id;
          });

          let currentIdx = currentFile.findIndex((i) => {
            return i.fileId === id;
          });

          console.log(id, currentIdx);

          currentQuestion[index] = {
            ...currentQuestion[index],
            response: currentData.fileId,
          };

          currentFile[currentIdx] = {
            ...this.state.fileDetails[currentIdx],
            fileBase64: currentData.fileLocation,
          };

          console.log("neeeew", currentFile);
          this.setState(
            {
              fileDetails: currentFile,
              // documentDetails: currentDocument,
              question: currentQuestion,
              index: 0,
              uploadLoading: false,
            },
            () => {
              console.log("sucess -", this.state.question);
            }
          );
        } else {
          this.setState({ index: 0, uploadLoading: false });
        }
      })
      .catch((err) => {
        this.setState({ uploadLoading: false }, () => {
          console.log("upload File err -", err);
        });
      });
  };

  responseToString() {
    console.log("converting to string");
    let currentQuestion = [...this.state.question];

    currentQuestion.map((item, index) => {
      if (item.typeId === 5) {
        let newRes = "";

        if (item.response) {
          newRes = item.response;
          // newRes.toString();
          // console.log('response string3', newRes.toString());

          currentQuestion[index] = {
            ...currentQuestion[index],
            response: newRes.toString(),
          };

          this.setState({ question: currentQuestion }, () => {
            console.log("converted to string");
            let newForm = {
              ...this.props.formData,
              EnrollmentSettingModels: this.state.question,
            };
            this.setState({ formData: newForm }, () => {
              console.log("extraForm -", newForm);
              this.props.setFormData(newForm);
              this.props.scrollToPage4();
            });
          });
        }
      } else {
        let newForm = {
          ...this.props.formData,
          EnrollmentSettingModels: this.state.question,
        };
        this.setState({ formData: newForm }, () => {
          console.log("extraForm -", newForm);
          this.props.setFormData(newForm);
          this.props.scrollToPage4();
        });
      }
    });
  }

  onFormSubmit(event) {
    event.preventDefault();
    console.log("final answer ---", this.state.question);
    this.setState({ submitted: true }, () => {
      if (this.isValid()) {
        this.responseToString();
      } else {
        document.getElementById("enrol-form-4").scrollIntoView();
        console.log("something missing");
      }
    });
  }

  checkboxSelect(item, ans, idx) {
    // return index === 0 ? index + 1 : index;

    let currentQuestion = [...this.state.question];
    let index = currentQuestion.findIndex((i) => {
      return i.id === item.id;
    });

    let newCheckedRes = "";

    let val = ans.answer;
    let checkedRes = currentQuestion[index].response;

    console.log(item.id, "--", val);

    if (checkedRes) {
      console.log("gt checkedres");
      newCheckedRes = checkedRes;
      if (newCheckedRes.includes(val)) {
        // console.log("gt val");
        // Check for duplicate or remove selected
        let newIndex = newCheckedRes.indexOf(val);

        // console.log("checkIdxx -", index);
        newCheckedRes.splice(newIndex, 1);
        currentQuestion[index] = {
          ...currentQuestion[index],
          response: newCheckedRes,
        };

        this.setState({ question: currentQuestion }, () => {
          console.log(this.state.question[index].response);
        });
      } else {
        // if selected new value
        newCheckedRes.push(val);

        currentQuestion[index] = {
          ...currentQuestion[index],
          response: newCheckedRes,
        };

        this.setState({ question: currentQuestion }, () => {
          console.log(item.id, "final -", this.state.question[index].response);
        });
      }
    } else {
      // if no value selected before
      newCheckedRes = [];
      newCheckedRes.push(val);
      console.log(item.id, newCheckedRes);
      currentQuestion[index] = {
        ...currentQuestion[index],
        response: newCheckedRes,
      };

      this.setState(
        {
          checkedIndex: idx,
          question: currentQuestion,
        },
        () => {
          console.log(item.id, "final -", this.state.question[index].response);
        }
      );
    }
  }

  render() {
    const { data, language, centerData, countryOption } = this.props;
    // console.log('form4 -', this.state.question);
    var number = 0;

    return (
      <div className="container-fluid" style={{ backgroundColor: "#f2f5fd" }}>
        <section id="enrol-form-4" className="container">
          <div className="container mt-4 mb-2">
            <div className="row px-5 my-3 justify-content-between align-items-center">
              <h5>{language.enrollmentForm}</h5>
              <h6 className="text-right">
                {format(language.page, `${"3/" + this.props.pageNum}`)}
              </h6>
            </div>
          </div>

          {/* SectionTitle = 1, ShortAnswer = 2, Paragraph = 3, MultiChoices = 4,
          Checkbox = 5, Dropdown = 6, FileUpload = 7, Date = 8, Time = 9 */}

          <div className="bg-white shadow p-5">
            {this.state.question.map((item, index) => {
              // console.log('published', index, item);
              if (item.published) {
                switch (item.typeId) {
                  case 1:
                    return (
                      <>
                        <h3 className="mb-1 text-capitalize">
                          {item.question}
                        </h3>
                        <br />
                      </>
                    );
                    break;
                  case 2:
                    number += 1;
                    return (
                      <div className="form-group">
                        <label className="col-form-label">
                          {number + ". " + item.question}
                          {item.isRequired && <span>*</span>}
                        </label>
                        <input
                          type="text"
                          name={item.type}
                          className={`form-control form-control-md 
              ${this.validRequired(item) ? "is-invalid" : null}`}
                          placeholder={language.enterAnswer}
                          required={item.isRequired}
                          // value={this.state.contactPersons[index].Name}
                          onChange={(event) => {
                            let currentQuestion = [...this.state.question];
                            let index = this.state.question.findIndex((i) => {
                              return i.id === item.id;
                            });

                            currentQuestion[index] = {
                              ...currentQuestion[index],
                              response: event.target.value,
                            };

                            this.setState({ question: currentQuestion });
                          }}
                        />
                        {/* <!-- Validation Error --> */}
                        <div className="invalid-feedback">
                          <div>Answer is required</div>
                        </div>
                        <br />
                      </div>
                    );
                    break;
                  case 3:
                    number += 1;
                    return (
                      <div className="form-group">
                        <label className="col-form-label">
                          {number + ". " + item.question}
                          {item.isRequired && <span>*</span>}
                        </label>

                        <textarea
                          type="text"
                          name={item.type}
                          rows={4}
                          className={`form-control form-control-md 
                  ${this.validRequired(item) ? "is-invalid" : null}`}
                          placeholder={language.enterAnswer}
                          required={item.isRequired}
                          // value={this.state.contactPersons[index].Name}
                          onChange={(event) => {
                            let currentQuestion = [...this.state.question];
                            let index = this.state.question.findIndex((i) => {
                              return i.id === item.id;
                            });

                            // console.log(index, this.state.question[index]);
                            currentQuestion[index] = {
                              ...currentQuestion[index],
                              response: event.target.value,
                            };

                            this.setState({ question: currentQuestion });
                          }}
                        />
                        {/* <!-- Validation Error --> */}
                        <div className="invalid-feedback">
                          <div>Answer is required</div>
                        </div>
                        <br />
                      </div>
                    );
                    break;
                  case 4:
                    number += 1;
                    return (
                      <div className="form-group">
                        <label className="col-form-label">
                          {number + ". " + item.question}
                          {item.isRequired && <span>*</span>}
                        </label>

                        {item.answerOptionList.map((ans, idx) => {
                          console.log(index, item.answerOptionList[index]);
                          return (
                            <div className="form-check mt-3 mb-3">
                              <input
                                type="radio"
                                className="form-check-input"
                                id={ans.answer + item.id}
                                name={ans.answer}
                                required={ans.isRequired}
                                checked={
                                  this.state.question[index].response ===
                                  ans.answer
                                }
                                onChange={(event) => {
                                  let currentQuestion = [
                                    ...this.state.question,
                                  ];
                                  let index = this.state.question.findIndex(
                                    (i) => {
                                      return i.id === item.id;
                                    }
                                  );

                                  let val = ans.answer;

                                  currentQuestion[index] = {
                                    ...currentQuestion[index],
                                    response: val,
                                  };

                                  this.setState({
                                    radioIndex: idx,
                                    question: currentQuestion,
                                  });
                                }}
                              />
                              <label
                                key={index}
                                className="form-check-label"
                                style={{ fontSize: 13 }}
                                for={ans.answer + item.id}
                              >
                                {ans.answer.replace("&amp;", "&")}
                              </label>
                            </div>
                          );
                        })}

                        {/* <!-- Validation Error --> */}
                        <div className="invalid-feedback">
                          <div>Answer is required</div>
                        </div>
                        <br />
                      </div>
                    );

                    break;
                  case 5:
                    number += 1;
                    return (
                      <div className="form-group">
                        <label className="col-form-label">
                          {number + ". " + item.question}
                          {item.isRequired && <span>*</span>}
                        </label>

                        {item.answerOptionList.map((ans, idx) => {
                          return (
                            <div key={idx} className="form-check mt-3 mb-3">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={ans.answer + item.id}
                                name={ans.answer}
                                onChange={(event) =>
                                  this.checkboxSelect(item, ans, idx)
                                }
                              />
                              <label
                                key={index}
                                className="form-check-label"
                                style={{ fontSize: 13 }}
                                for={ans.answer + item.id}
                              >
                                {ans.answer}
                              </label>
                            </div>
                          );
                        })}

                        {/* <!-- Validation Error --> */}
                        <div className="invalid-feedback">
                          <div>Answer is required</div>
                        </div>
                        <br />
                      </div>
                    );
                    break;
                  case 6:
                    number += 1;
                    return (
                      <div className="form-group">
                        <label>
                          {number + ". " + item.question}
                          {item.isRequired && <span>*</span>}
                        </label>
                        <select
                          className={`form-control custom-select ${
                            this.validRequired(item) ? "is-invalid" : null
                          }`}
                          defaultValue=""
                          value={this.state.country}
                          required={item.isRequired}
                          onChange={(event) => {
                            let currentQuestion = [...this.state.question];
                            let index = this.state.question.findIndex((i) => {
                              return i.id === item.id;
                            });

                            currentQuestion[index] = {
                              ...currentQuestion[index],
                              response: event.target.value,
                            };

                            this.setState({ question: currentQuestion }, () => {
                              console.log(
                                "final dropdown -",
                                this.state.question[index].response
                              );
                            });
                          }}
                        >
                          <option value="" disabled>
                            {"Please select an option"}
                          </option>
                          {item.answerOptionList.map((item, index) => {
                            return (
                              <option key={index} value={item.answer}>
                                {item.answer}
                              </option>
                            );
                          })}
                        </select>
                        {/* <!-- Validation Error --> */}
                        <div className="invalid-feedback">
                          <div>{"Please select an option"}</div>
                        </div>
                        <br />
                      </div>
                    );
                    break;
                  case 7:
                    number += 1;
                    return (
                      <>
                        <div
                          key={index}
                          className="fileupload mt-1"
                          id="container-file"
                        >
                          <label className="mt-4">
                            {number + ". " + item.question}
                            {item.isRequired && <span>*</span>}
                          </label>
                          <span className={`btn btn-default fileinput-button`}>
                            <div className="text-center">
                              <span
                                className="outline-circle"
                                style={{
                                  display: "inline-block",
                                }}
                              >
                                <i className="fa fa-arrow-up border-circle" />
                              </span>
                            </div>
                            <i
                              className={`${
                                this.state.uploadLoading ? "loading" : "upload"
                              }`}
                            ></i>

                            {!this.state.uploadLoading && (
                              <input
                                className="fileUploadMobile"
                                type="file"
                                name="files"
                                id="file"
                                data-browse-on-zone-click="true"
                                className={`${
                                  this.validRequired(item) ? "is-invalid" : null
                                }`}
                                onChange={(event) =>
                                  this.getUploadFiles(event, item.id)
                                }
                                disabled={this.state.uploadLoading}
                                required={item.isRequired}
                              />
                            )}
                          </span>
                          {/* <!-- Validation Error --> */}
                          <div className="invalid-feedback">
                            <div>{"Please select an option"}</div>
                          </div>
                          <br />
                        </div>

                        <br />
                        {this.state.fileDetails.length > 0 ? (
                          <>
                            {this.state.fileDetails.map((file, idx) => {
                              if (file.fileId === item.id) {
                                return (
                                  <div
                                    key={idx}
                                    className="preview-frame4 mt-2"
                                  >
                                    <div
                                      onClick={() =>
                                        this.removeFile(item.id, index)
                                      }
                                    >
                                      <i className="fa fa-remove remove-icon"></i>
                                    </div>
                                    {this.state.uploadLoading &&
                                    file.fileId === item.id ? (
                                      <Loader
                                        css={`
                                          display: block;
                                          margin: 0 auto;
                                          border-color: red;
                                        `}
                                        className="mt-2"
                                        size={75}
                                        color={"#123abc"}
                                        loading={true}
                                      />
                                    ) : (
                                      <>
                                        {file.fileType === "application/pdf" ? (
                                          <div className="file-pdf text-center">
                                            <i className="fa fa-file-pdf-o text-center pdf-icon"></i>
                                          </div>
                                        ) : (
                                          <img
                                            src={file.fileBase64}
                                            className="text-center mt-1 mb-1 mr-0 p-0 img-logo"
                                            alt="images.png"
                                          />
                                        )}
                                      </>
                                    )}
                                    <div className="file-caption">
                                      <div className="file-name">
                                        {file.fileName}
                                      </div>
                                      <div className="file-info">
                                        <samp>{file.fileSize}</samp>
                                      </div>
                                    </div>

                                    <br />
                                  </div>
                                );
                              }
                            })}
                          </>
                        ) : null}
                      </>
                    );
                    break;
                  case 8:
                    number += 1;
                    return (
                      <div className="form-group">
                        <label>
                          {number + ". " + item.question}
                          {item.isRequired && <span>*</span>}
                        </label>
                        <div className="input-group mb-2">
                          <DatePicker
                            selected={this.state.defaultDate}
                            style={{ width: "100%", borderRadius: 25 }}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            id="exampleDate"
                            placeholder={language.selectDob}
                            // maxDate={new Date()}
                            value={new Date()}
                            // value={new }
                            dateFormat={"dd/MM/yyyy"}
                            required={item.isRequired}
                            onChange={(date) => {
                              let currentQuestion = [...this.state.question];
                              let index = this.state.question.findIndex((i) => {
                                return i.id === item.id;
                              });

                              let value = moment(new Date(date)).format(
                                "yyyy-MM-DD"
                              );

                              currentQuestion[index] = {
                                ...currentQuestion[index],
                                response: value,
                              };

                              this.setState({
                                question: currentQuestion,
                                defaultDate: date,
                              });
                            }}
                          />
                          {/* <!-- Validation Error --> */}
                          <div className="invalid-feedback">
                            <div>Date is required</div>
                          </div>
                        </div>

                        <br />
                      </div>
                    );
                    break;
                  case 9:
                    number += 1;
                    return (
                      <div className="form-group">
                        <label>
                          {number + ". " + item.question}
                          {item.isRequired && <span>*</span>}
                        </label>
                        <div className="input-group mb-2">
                          <input
                            type="time"
                            className={`form-control form-control-md 
                            ${this.validRequired(item) ? "is-invalid" : null}`}
                            onChange={(event) => {
                              let currentQuestion = [...this.state.question];
                              let index = this.state.question.findIndex((i) => {
                                return i.id === item.id;
                              });

                              currentQuestion[index] = {
                                ...currentQuestion[index],
                                response: event.target.value,
                              };

                              this.setState({ question: currentQuestion });
                            }}
                            required={item.isRequired}
                          />
                        </div>
                        {/* <!-- Validation Error --> */}
                        <div className="invalid-feedback">
                          <div>Time is required</div>
                        </div>
                        <br />
                      </div>
                    );
                    break;
                  default:
                    break;
                }
              }
            })}
            <div className="row mt-4">
              <div className="col-md-12 text-center">
                <button
                  className="btn-prev"
                  type="submit"
                  onClick={() => this.props.backToPage2()}
                >
                  {language.prev}
                </button>
                <button
                  className="btn-next justify-content-between"
                  type="submit"
                  onClick={(event) => this.onFormSubmit(event)}
                >
                  {language.next}
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
