import React from 'react';

import '../style/Marker.css';
import InfoWindow from './InfoWindow';

export default class Marker extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showInfo: this.props.showInfo,
      index: 0,
    };
  }

  closeInfoWindow = () => {
    this.setState({ showInfo: false });
  };

  render() {
    const {
      markerData,
      showWindow,
      markerClick,
      index,
      showInfo,
      markerClose,
    } = this.props;
    // console.log('showing info - ', markerData.logoFileLocation);
    return (
      <div style={{ cursor: 'pointer' }} onClick={() => markerClick(index)}>
        {markerData && markerData.logoAsMarker ? (
          <img src={markerData.logoFileLocation} sizes={5} />
        ) : (
          <i
            className='fa fa-map-marker marker'
            style={{ color: 'red', fontSize: 25 }}
          ></i>
        )}
        {showWindow && showInfo && (
          <InfoWindow
            markerData={markerData}
            closeInfo={this.closeInfoWindow}
          />
        )}
      </div>
    );
  }
}
