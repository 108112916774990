import React from 'react';
import Loader from 'react-spinners/BounceLoader';

export default class Loading extends React.PureComponent {
  constructor() {
    super();
  }
  render() {
    const { size, color } = this.props;
    return (
      <div
        className='sweet-loading w-100'
        style={{ position: 'absolute', textAlign: 'center', top: '30%' }}
      >
        <Loader
          css={`
            display: block;
            margin: 0 auto;
            border-color: red;
          `}
          size={size ? size : 100}
          color={color ? color : '#123abc'}
          loading={true}
        />
      </div>
    );
  }
}
