import React from 'react';
import { Link } from 'react-router-dom';

import '../style/CenterBar.css';
import centerIcon from '../assets/default-center.png';
import Tags from './Tags';

export default class CenterBar extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    let { center, register } = this.props;
    let centerImg = center.fileLocation || center.companyLogo;
    let centerTagName =
      center.tagNameDetails || center.centerDirectoryTagDetails;
    console.log('centertag - ', center);
    return (
      <section className='container py-2'>
        <div className='bg-white p-2'>
          <div className='row align-items-center p-0'>
            <div className='col-sm-2 col-md-2 text-center'>
              <img
                src={centerImg ? centerImg : centerIcon}
                className='text-center mt-1 mb-1 mr-0 p-0 img-logo'
                alt={center.companyName + '.png'}
              />
            </div>

            {/* <!-- Without register button --> */}

            <div
              className={`${
                !register ? 'col-sm-10 col-md-10' : 'col-sm-7 col-md-7'
              } align-self-center mt-2`}
            >
              <div className='col-md-12'>
                <h1 className='center-name text-capitalize d-inline-block'>
                  {center.displayName ? center.displayName : center.companyName}
                </h1>
                <div className='ml-3'>
                  <Tags centerTag={centerTagName} />
                </div>
                <address className='address pt-1'>{center.fullAddress}</address>
              </div>
            </div>

            {/* <!-- With register button --> */}

            {register && (
              <Link
                className='col-sm-3 col-md-3 align-self-center'
                to={`/centers/${center.uniqueToken}/online-enrollment`}
                style={{ cursor: 'pointer', textDecoration: 'none' }}
              >
                <a
                  className='btn btn-primary btn-block text-center'
                  style={{ textDecoration: 'none' }}
                >
                  Register Now
                </a>
              </Link>
            )}
          </div>
        </div>
      </section>
    );
  }
}
