import React from 'react';
import backToTop from '../assets/back-to-top.png';

import '../style/LanguageBar.css';
export default class LanguageBar extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className='language-bar'>
        <a
          className='language'
          style={{
            padding: 10,
            color: '#f2f5fd',
            alignSelf: 'center',
            fontSize: 15,
          }}
          onClick={() => this.props.setLanguage('en')}
        >
          English
        </a>

        <a
          className='language'
          style={{
            padding: 10,
            color: '#f2f5fd',
            textAlign: 'center',
            fontSize: 15,
            margin: 10,
          }}
          onClick={() => this.props.setLanguage('zh')}
        >
          中文
        </a>
        <a
          className='language'
          style={{
            padding: 10,
            color: '#f2f5fd',
            textAlign: 'center',
            fontSize: 15,
            margin: 10,
          }}
          onClick={() => this.props.setLanguage('my')}
        >
          Malay
        </a>
        <a href='#' className='back-to-top-icon'>
          <img src={backToTop} />
        </a>
      </div>
    );
  }
}
