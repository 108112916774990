import React from 'react';
import { Link } from 'react-router-dom';

export default class InfoWindow extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { markerData } = this.props;
    const infoWindowStyle = {
      position: 'relative',
      bottom: 150,
      left: '-45px',
      width: 220,
      backgroundColor: 'white',
      boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
      padding: 10,
      fontSize: 14,
      zIndex: 100,
    };
    return (
      <div style={infoWindowStyle}>
        <div style={{ fontSize: 16, fontWeight: 'bold' }}>
          {markerData.displayName
            ? markerData.displayName
            : markerData.companyName}
        </div>
        <div style={{ fontSize: 13 }}>
          <span style={{ color: 'grey', fontStyle: 'italic', marginTop: 10 }}>
            {markerData.cityState}
          </span>
          {/* <span style={{ color: 'orange' }}>
            {String.fromCharCode(9733).repeat(Math.floor(place.rating))}
          </span>
          <span style={{ color: 'lightgrey' }}>
            {String.fromCharCode(9733).repeat(5 - Math.floor(place.rating))}
          </span> */}
        </div>
        {/* <div
          onClick={() => this.props.closeInfo()}
          style={{
            fontSize: 14,
            color: 'red',
            position: 'absolute',
            top: 10,
            right: 8,
            fontWeight: 'bold',
            cursor: 'pointer',
          }}
        >
          {'x'}
        </div> */}
        {/* <div style={{ fontSize: 14, color: 'grey' }}>
          {'$'.repeat(place.price_level)}
        </div> */}

        <Link to={'/centers/' + markerData.uniqueToken}>
          <div
            style={{
              fontSize: 14,
              color: 'blue',
              textAlign: 'right',
              marginTop: 10,
              cursor: 'pointer',
            }}
            // onClick={() => console.log('info -', markerData.uniqueToken)}
          >
            {'View Details'}
          </div>
        </Link>
      </div>
    );
  }
}
