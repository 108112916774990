import axios from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';
import CenterBar from '../components/CenterBar';
import Footer from '../components/Footer';
import LanguageBar from '../components/LanguageBar';
import Navbar from '../components/NavigationBar';
import { BASE_URL } from '../constants';
import GoogleMapReact from 'google-map-react';
import ImageGallery from 'react-image-gallery';
import MetaTags from 'react-meta-tags';

import '../style/Center.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import EnrolForm from './EnrolForm';
import Loading from '../components/Loading';
import Marker from '../components/Marker';

// icons
import ins from '../assets/ins.png';
import fb from '../assets/fb.png';
import wa from '../assets/wa.png';
import tw from '../assets/tw.png';
import link from '../assets/in.png';

export default class Center extends React.Component {
  constructor() {
    super();

    this.state = {
      id: '',
      center: [],
      centerImg: [],
      centerOpt: [],

      testImg: '',
      keyword: '',
      displayImg: false,

      activeTab: '1',
      initial: true,

      slideIndex: 0,
      animating: false,

      defLatitude: 0,
      defLongitude: 0,

      markerLat: '',
      markerLong: '',
    };
  }

  componentDidMount() {
    //this.getGeolocation();
    this._getCenter();
  }

  getGeolocation() {
    if ('geolocation' in navigator) {
      console.log('Available');
      navigator.geolocation.getCurrentPosition((position) => {
        // console.log('geo :', position);
        console.log('Latitude is :', position.coords.latitude);
        console.log('Longitude is :', position.coords.longitude);
        this.setState({
          defLatitude: position.coords.latitude,
          defLongitude: position.coords.longitude,
        });
      });
    } else {
      console.log('Not Available');
    }
  }

  _getCenter() {
    this.setState({ id: this.props.match.params.id });
    axios
      .get(
        BASE_URL + 'Api/GetCenterDirectoryDetail/' + this.props.match.params.id
      )
      .then((res) => {
        if (res.data.result.success) {
          // console.log(res.data.data);

          // this.state.this.state.center.push(res.data.data);
          this.setState(
            {
              center: res.data.data,
              centerImg: res.data.data.directoryPictureDetails,
              centerOpt: res.data.data.operatingHourDetails[0],
              initial: false,
            },
            () => {
              this._getKeywords(res.data.data);
              this.state.centerImg.map((item) => {
                if (item.directoryPicture.includes('No Picture')) {
                  console.log('No Picture');
                } else {
                  this.setState({ displayImg: true });
                }
              });
            }
          );
        }
      })
      .catch((err) => {
        console.log('center error - ', err);
      });
  }

  _getKeywords(tag) {
    let keyword = tag.tagNameDetails || tag.centerDirectoryTagDetails;

    let newKeyWord = keyword
      .map((item) => {
        return item.tagName;
      })
      .join();

    this.setState({ keyword: newKeyWord });
  }

  directionMap() {
    let destination =
      this.state.center.latitude + ',' + this.state.center.longitude;

    window.open(
      'https://www.google.com/maps/search/?api=1&query=' + destination
    );
  }

  handleImg(img) {
    let newImg = [];
    img.map((item, index) => {
      // console.log(item.directoryPicture);
      // if (item.directoryPicture.indexOf('.pdf') == -1) {
      newImg.push({
        original: item.directoryPicture.toString(),
        thumbnail: item.directoryPicture.toString(),
        originalHeight: 450,
        thumbnailHeight: 65,
        thumbnailClass: 'thumbnail-fit',
        originalAlt: `${this.state.center.companyName}.png`,
      });
      // }
    });

    console.log(newImg);

    return newImg;
  }

  // getPdfThubn

  toggle = (tab) => {
    this.setState({ activeTab: tab });
  };

  prev = () => {
    let nexIndex =
      this.state.slideIndex === 0
        ? this.state.centerImg.length - 1
        : this.state.slideIndex - 1;

    this.setState({ slideIndex: nexIndex });
  };

  next = () => {
    let nexIndex =
      this.state.slideIndex === this.state.centerImg.length - 1
        ? 0
        : this.state.slideIndex + 1;

    this.setState({ slideIndex: nexIndex });
  };

  gotoIndex = (index) => {
    this.setState({ slideIndex: index });
  };

  validUrl(link) {
    if (link.indexOf('http://') === 0 || link.indexOf('https://') === 0) {
      return true;
    } else {
      return false;
    }
  }

  createMapOptions(maps) {
    // next props are exposed at maps
    // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
    // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
    // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
    // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
    // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
    return {
      zoomControlOptions: {
        position: maps.ControlPosition.RIGHT_BOTTOM,
        style: maps.ZoomControlStyle.SMALL,
      },
      mapTypeControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT,
      },
      mapTypeControl: false,
    };
  }

  render() {
    console.log('center - ', this.state.center);
    return (
      <>
        <MetaTags>
          <title>{this.state.center.companyName + ' | iKEY Directory'}</title>
          <meta
            name='description'
            content={
              this.state.center.description +
              ',' +
              this.state.center.googleSearchDescription
            }
          />
          <meta
            name='keywords'
            content={
              this.state.center.companyName +
              ',' +
              this.state.keyword +
              ',iKEY EDUTECH, iKEY edutech sdn bhd, iKEY parent, iKEY student, iKEY educator, css/Style_v9.cssborderless learning,  iKEY Directory, iKEY directory, education platform, directory'
            }
          />
          <meta name='author' content='iKEY EDUTECH Sdn Bhd' />
        </MetaTags>
        <Navbar {...this.props} />

        {this.state.initial ? (
          <Loading />
        ) : (
          <div className='container mt-5'>
            <section className='container px-3 mt-5'>
              <CenterBar center={this.state.center} register />
              {/* <LanguageBar {...this.props} /> */}
              {this.state.displayImg && (
                <ImageGallery
                  items={this.handleImg(this.state.centerImg)}
                  showFullscreenButton
                  showThumbnails
                  showIndex={true}
                  thumbnailPosition={'left'}
                />
              )}
              {/* <div className='my-3 pt-4' style={{ backgroundColor: '#f2f5fd' }}>
                <Carousel
                  activeIndex={this.state.slideIndex}
                  next={this.next}
                  previous={this.prev}
                >
                  {this.state.centerImg.map((item, i) => {
                    return (
                      <CarouselItem key={i}>
                        <img
                          src={item.directoryPicture}
                          alt=''
                          className='d-block '
                          style={{
                            maxWidth: 50,
                            objectFit: 'contain',
                          }}
                        />
                        <CarouselCaption
                          captionText={`${this.state.slideIndex + 1} / ${
                            this.state.centerImg.length
                          }`}
                          className='custom-caption'
                        ></CarouselCaption>
                      </CarouselItem>
                    );
                  })}
                  <CarouselControl
                    direction='prev'
                    directionText='Previous'
                    onClickHandler={this.prev}
                  />
                  <CarouselControl
                    direction='next'
                    directionText='Next'
                    onClickHandler={this.next}
                  />
                </Carousel>
                <div className='row justify-content-center p-2'>
                  {this.state.centerImg.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={`m-2 ${
                          this.state.slideIndex === index ? 'img-border' : null
                        }`}
                      >
                        <img
                          src={item.directoryPicture}
                          alt=''
                          className='img-responsive indicators-size'
                          style={{
                            maxWidth: 100,
                            height: 90,
                            objectFit: 'cover',
                            opacity:
                              this.state.slideIndex === index ? null : 0.75,
                          }}
                          onClick={() =>
                            this.state.slideIndex === index
                              ? null
                              : this.gotoIndex(index)
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </div> */}
            </section>
            <div className='divider mb-3'></div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  active={this.state.activeTab === '1'}
                  // className={classnames({ active: activeTab === '1' })}
                  onClick={() => this.toggle('1')}
                >
                  Center Info
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  active={this.state.activeTab === '2'}
                  // className={classnames({ active: activeTab === '2' })}
                  onClick={() => this.toggle('2')}
                >
                  Register
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId='1'>
                <section id='center-info' className='row py-3'>
                  <div className='col-sm-6 col-md-6 order-2'>
                    <div className='mb-4'>
                      <h3 className='center-subtitle'>Learner Age</h3>
                      <p>{this.state.center.learnerAge}</p>
                    </div>
                    <div className='mb-4'>
                      <h3 className='center-subtitle'>Related Courses</h3>
                      <p>{this.state.center.relatedCourse}</p>
                    </div>

                    <div className='divider my-4 w-75'></div>
                    <div className='mb-4'>
                      <h3 className='center-subtitle'>Description</h3>
                      <p>{this.state.center.description}</p>
                    </div>
                    <div className='mb-4'>
                      <h3 className='center-subtitle'>Website</h3>
                      <a
                        href={
                          this.state.center.website &&
                          this.validUrl(this.state.center.website)
                            ? this.state.center.website
                            : 'http://' + this.state.center.website
                        }
                        target='_blank'
                      >
                        {this.state.center.website}
                      </a>
                    </div>
                    <div className='mb-4'>
                      <h3 className='center-subtitle'>Open Hours</h3>
                      {this.state.centerOpt && (
                        <div className='row align-items-center container'>
                          <div className='d-inline'>
                            <p>Monday :</p>
                            <p>Tuesday :</p>
                            <p>Wednesday :</p>
                            <p>Thursday :</p>
                            <p>Friday :</p>
                            <p>Saturday :</p>
                            <p>Sunday :</p>
                          </div>
                          <div className='d-inline ml-2'>
                            <p>{this.state.centerOpt.monOperatingHour}</p>
                            <p>{this.state.centerOpt.tueOperatingHour}</p>
                            <p>{this.state.centerOpt.wedOperatingHour}</p>
                            <p>{this.state.centerOpt.thuOperatingHour}</p>
                            <p>{this.state.centerOpt.friOperatingHour}</p>
                            <p>{this.state.centerOpt.satOperatingHour}</p>
                            <p>{this.state.centerOpt.sunOperatingHour}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <!-- <div className="col-sm-2 col-md-2 mr-2 ml-2 order-3"></div> --> */}
                  <div className='col-sm-6 col-md-6 order-4'>
                    <div>
                      <div>
                        <h5>Fullfill your requirement?</h5>
                        <p>This center is available for entry</p>
                        <Link
                          to={`/centers/${this.state.id}/online-enrollment`}
                        >
                          <a className='btn btn-md btn-primary col-sm-9'>
                            Register Now
                          </a>
                        </Link>
                        <div className='row image-row'>
                          {this.state.center.instagram && (
                            <a
                              href={
                                this.state.center.instagram &&
                                this.validUrl(this.state.center.instagram)
                                  ? this.state.center.instagram
                                  : 'http://www.instagram.com/' +
                                    this.state.center.instagram.trim()
                              }
                              target='_blank'
                            >
                              <img
                                src={ins}
                                width={50}
                                height={50}
                                className={'mr-2'}
                                alt={
                                  this.state.center.companyName +
                                  '-Instagram.png'
                                }
                              />
                              {/* <i
                                className='fa fa-instagram'
                                style={{ fontSize: 50 }}
                              /> */}
                            </a>
                          )}
                          {this.state.center.facebook && (
                            <a
                              href={
                                this.state.center.facebook &&
                                this.validUrl(this.state.center.facebook)
                                  ? this.state.center.facebook
                                  : 'http://www.facebook.com/' +
                                    this.state.center.facebook.trim()
                              }
                              target='_blank'
                            >
                              <img
                                src={fb}
                                width={50}
                                height={50}
                                className={'mr-2'}
                                alt={
                                  this.state.center.companyName +
                                  '-Facebook-Fan-Page.png'
                                }
                              />
                              {/* <i
                                className='fa fa-facebook'
                                style={{ fontSize: 50 }}
                              ></i> */}
                            </a>
                          )}
                          {this.state.center.twitter && (
                            <a href={this.state.center.twitter} target='_blank'>
                              <img
                                src={tw}
                                width={50}
                                height={50}
                                className={'mr-2'}
                                alt={
                                  this.state.center.companyName + '-Twitter.png'
                                }
                              />
                              {/* <i
                                className='fa fa-twitter'
                                style={{ fontSize: 50 }}
                              ></i> */}
                            </a>
                          )}
                          {this.state.center.linkedIn && (
                            <a
                              href={this.state.center.linkedIn}
                              target='_blank'
                            >
                              <img
                                src={link}
                                width={50}
                                height={50}
                                className={'mr-2'}
                                alt={
                                  this.state.center.companyName +
                                  '-LinkedIn.png'
                                }
                              />
                              {/* <i
                                className='fa fa-linkedIn'
                                style={{ fontSize: 50 }}
                              ></i> */}
                            </a>
                          )}
                          {this.state.center.whatsApp && (
                            <a
                              href={
                                'https://wa.me/' +
                                this.state.center.whatsApp.replace('+', '')
                              }
                              target='_blank'
                            >
                              <img
                                src={wa}
                                width={50}
                                height={50}
                                className={'mr-2'}
                                alt={
                                  this.state.center.companyName +
                                  '-Whatsapp.png'
                                }
                              />
                              {/* <i
                                className='fa fa-whatsapp'
                                style={{ fontSize: 50 }}
                              ></i> */}
                            </a>
                          )}
                        </div>
                      </div>

                      <div className='divider my-4'></div>

                      <div className='mb-4'>
                        <h3 className='center-subtitle'>Address</h3>
                        <address>{this.state.center.fullAddress}</address>
                      </div>

                      <div className='divider my-4'></div>
                      {this.state.center.latitude ||
                      this.state.center.longitude ? (
                        <>
                          <div
                            style={{
                              height: '450px',
                              width: '100%',
                            }}
                          >
                            <GoogleMapReact
                              bootstrapURLKeys={{
                                key: 'AIzaSyBLqLVq7KdKBDl88uRRqMIBBuHtG4fhuM4',
                              }}
                              // center={[]}
                              defaultCenter={{
                                lat: parseFloat(this.state.center.latitude),
                                lng: parseFloat(this.state.center.longitude),
                                //lat: this.state.defLatitude,
                                //lng: this.state.defLongitude,
                              }}
                              yesIWantToUseGoogleMapApiInternals
                              options={this.createMapOptions}
                              defaultZoom={15}
                            >
                              {/* <AnyReactComponent
                            lat={this.state.center.latitude}
                            lng={this.state.center.longitude}
                            text='My Marker'
                          /> */}
                              <Marker
                                lat={this.state.center.latitude}
                                lng={this.state.center.longitude}
                              />
                            </GoogleMapReact>
                          </div>
                          <button
                            className='btn btn-outline-primary btn-md w-100 mt-3'
                            type='submit'
                            onClick={() => this.directionMap()}
                          >
                            <span
                              className='fa fa-location-arrow'
                              id='location'
                            ></span>
                            Direction
                          </button>
                        </>
                      ) : null}
                    </div>
                  </div>
                </section>
              </TabPane>
              <TabPane tabId='2'>
                <Row>
                  <Col>
                    <EnrolForm noCenterBar {...this.props} />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </div>
        )}
        <br />
        {!this.state.initial && <Footer />}
      </>
    );
  }
}
