import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';

import { en, my, zh, jp } from '../locales/index';

// Initial State
const initialState = {
  language: '',
  languageType: '',
  centerData: [],
  formData: '',
};

// Reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'setLanguage':
      return {
        ...state,
        language: action.value,
      };
    case 'setCenterData':
      return { ...state, centerData: action.value };
    case 'setFormData':
      return { ...state, formData: action.value };
    default:
      return state;
  }
};

const store = createStore(reducer, applyMiddleware(thunkMiddleware));

// Actions
const setLanguage = (language) => {
  // console.log('langType -', language);
  let newLanguage = '';
  if (language === 'zh') {
    newLanguage = zh;
  } else if (language === 'my') {
    newLanguage = my;
  } else if (language === 'jp') {
    newLanguage = jp;
  } else {
    newLanguage = en;
  }
  return {
    type: 'setLanguage',
    value: newLanguage,
  };
};

const setCenterData = (centerData) => {
  // console.log(centerData);
  return {
    type: 'setCenterData',
    value: centerData,
  };
};

const setFormData = (formData) => {
  console.log(formData);
  return {
    type: 'setFormData',
    value: formData,
  };
};

//
// Store...
//

export default store;
export { setLanguage, setCenterData, setFormData };
