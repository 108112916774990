import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Directory from './screens/Directory';
import EnrolForm from './screens/EnrolForm';
import Center from './screens/Center';

import { Provider, connect } from 'react-redux';
import store, {
  setLanguage,
  setCenterData,
  setFormData,
} from './redux/app-redux';
import SearchResult from './screens/SearchResult';

const mapStateToProps = (state) => {
  return {
    language: state.language,
    centerData: state.centerData,
    formData: state.formData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLanguage: (language) => dispatch(setLanguage(language)),
    setCenterData: (centerData) => dispatch(setCenterData(centerData)),
    setFormData: (formData) => dispatch(setFormData(formData)),
  };
};

function Main() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path='/'
          component={connect(mapStateToProps, mapDispatchToProps)(Directory)}
        />
        <Route
          exact
          path='/:search'
          component={connect(mapStateToProps, mapDispatchToProps)(SearchResult)}
        />
        <Route
          exact
          path='/centers/:id'
          component={connect(mapStateToProps, mapDispatchToProps)(Center)}
        />
        <Route
          exact
          path='/centers/:id/online-enrollment'
          component={connect(mapStateToProps, mapDispatchToProps)(EnrolForm)}
        />
      </Switch>
    </Router>
  );
}

export default Main;
