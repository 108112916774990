import axios from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import Navbar from '../components/NavigationBar';
import LanguageBar from '../components/LanguageBar';
import { BASE_URL } from '../constants';
import GoogleMapReact from 'google-map-react';
import MetaTags from 'react-meta-tags';
import { firebase } from '../utils/firebase';

import '../style/Directory.css';
import Loading from '../components/Loading';
import ReactPaginate from 'react-paginate';
import Tags from '../components/Tags';
import Marker from '../components/Marker';

import centerIcon from '../assets/default-center.png';

const AnyReactComponent = ({ text }) => <div>{text}</div>;
const remoteConfig = firebase.remoteConfig();

remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

export default class Directory extends React.Component {
  constructor() {
    super();
    this.state = {
      directory: [],
      searchResult: [],

      pageIndex: 0,
      pageLoad: false,
      pageCount: 0,
      initial: true,

      title: '',
      keyword: '',
      search: '',
      searchIndex: 0,
      showResult: false,

      latitude: 5.4164,
      longitude: 100.3327,
      defLatitude: '',
      defLongitude: '',
      zoom: 11,
      markerIndex: '',
    };
  }

  componentDidMount() {
    // this.getRemoteTitle();
    Promise.all([this._getDirectory(), this.getGeolocation()]);
    this.props.setLanguage('en');
  }

  getRemoteTitle() {
    let remote = remoteConfig.getAll();
    let title = remote.directory_title;

    console.log(title);
    if (title._source === 'remote') {
      this.setState({ title: title._value });
    } else {
      this.setState({ title: 'iKEY EDUTECH' });
    }
  }

  getGeolocation() {
    if ('geolocation' in navigator) {
      console.log('Available');
      navigator.geolocation.getCurrentPosition((position) => {
        // console.log('geo :', position);
        console.log('Latitude is :', position.coords.latitude);
        console.log('Longitude is :', position.coords.longitude);
        this.setState({
          defLatitude: position.coords.latitude,
          defLongitude: position.coords.longitude,
        });
      });
    } else {
      console.log('Not Available');
    }
  }

  _getDirectory() {
    axios
      .get(
        BASE_URL +
          'Api/GetCenterDirectoryList/v2?pageIndex=' +
          this.state.pageIndex +
          '&pageSize=10'
      )
      .then((res) => {
        if (res.data.result.success) {
          console.log('dir -', res.data.data);
          this.setState(
            {
              directory: res.data.data.centerDirectoryList,
              pageCount: res.data.data.totalCount / 10,
              initial: false,
            },
            () => {
              this._getKeyWord(res.data.data.centerDirectoryList);
              if (this.state.pageLoad || this.state.pageIndex > 0) {
                this.setState({ pageLoad: false }, () => {
                  document.getElementById('directory-home').scrollIntoView();
                });
              }
            }
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  _getKeyWord(tag) {
    let keyword = tag
      .map((item) => {
        return item.companyName;
      })
      .join();

    this.setState({ keyword: keyword });
  }

  getsearchResult = (event) => {
    let key = event ? event.key : null;

    if (key === 'Enter') {
      this.props.history.push(`/${this.state.search}`);
    }
    // else {
    //   this.props.history.push(`/directory/${this.state.search}`);
    // }
    // this.setState({ search: search }, () => {
    //   console.log('search - ', this.state.search);
    //   if (this.state.search.length > 0) {
    //     axios
    //       .get(
    //         BASE_URL +
    //           'Api/GetCenterDirectoryList?centerName=&searchingInput=' +
    //           this.state.search +
    //           '&PageIndex=' +
    //           this.state.searchIndex +
    //           '&PageSize=10'
    //       )
    //       .then((res) => {
    //         if (res.data.result.success) {
    //           this.setState({ searchResult: res.data.data, showResult: true });
    //         }
    //       })
    //       .catch((err) => {
    //         console.log('search error -', err);
    //       });
    //   } else {
    //     this.setState({ showResult: false }, () => {
    //       console.log('Search null');
    //     });
    //   }
    // });
  };

  handlePageClick = (data) => {
    console.log(data.selected);
    this.setState(
      { pageLoad: true, pageIndex: data.selected, directory: [] },
      () => {
        this._getDirectory();
      }
    );
  };

  _onMarkerClick = (index) => {
    if (index) {
      this.setState({ markerIndex: index }, () => {
        console.log('info', this.state.markerIndex);
      });
    } else {
      this.setState({ markerIndex: '' }, () => {
        console.log('closing info', this.state.markerIndex);
      });
    }
  };

  _onMarkerClose = () => {};

  render() {
    return (
      <>
        <MetaTags>
          <title>{'iKey Directory'}</title>
          <meta name='description' content={'iKey Directory'} />
          <meta
            name='keywords'
            content={
              this.state.keyword +
              ',iKEY EDUTECH, iKEY edutech sdn bhd, iKEY parent, iKEY student, iKEY educator, css/Style_v9.cssborderless learning,  iKEY Directory, iKEY directory, education platform, directory'
            }
          />
          <meta name='author' content='iKEY EDUTECH Sdn Bhd' />
        </MetaTags>
        <Navbar {...this.props} />
        {this.state.initial ? (
          <Loading />
        ) : (
          <div id='directory-home' className='container py-4 px-3 mt-5'>
            {/* <LanguageBar {...this.props} /> */}
            <section className='container my-5'>
              <div id='custom-search-input'>
                <div className='input-group col-md-12'>
                  <input
                    type='text'
                    className='form-control input-lg'
                    placeholder='Search by center name or states'
                    onChange={(event) =>
                      this.setState({ search: event.target.value })
                    }
                    onKeyDown={(event) => this.getsearchResult(event)}
                    style={{ borderColor: '#2f5ddb', borderWidth: 2 }}
                  />
                  <div
                    className='input-group-btn'
                    // style={{
                    //   backgroundColor: '#2f5ddb',
                    //   verticalAlign: 'center',
                    // }}
                  >
                    <Link
                      to={'/' + this.state.search}
                      style={{ cursor: 'pointer' }}
                    >
                      <i
                        className='fa fa-search'
                        // style={{ color: 'white', backgroundColor: '#2f5ddb' }}
                      ></i>
                    </Link>
                    {/* <button
                      className='btn btn-info btn-lg'
                      type='button'
                      // onClick={() => this.getsearchResult()}
                    >
                      <span
                        className='fa fa-search'
                        // style={{ color: 'white', backgroundColor: '#2f5ddb' }}
                      ></span>
                    </button> */}
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div className='row mt-4'>
                {/* <!-- Left --> */}
                <div className='col-sm-7 col-md-7 py-3 order-1'>
                  {this.state.pageLoad ? (
                    <Loading />
                  ) : (
                    <>
                      {this.state.directory.map((item, index) => {
                        return (
                          <div key={index} className='col-sm-12 col-md-12 mb-3'>
                            <div className='row'>
                              <div className='col-sm-3 col-md-3 order-1'>
                                <Link
                                  to={'/centers/' + item.uniqueToken}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <img
                                    src={
                                      item.fileLocation
                                        ? item.fileLocation
                                        : centerIcon
                                    }
                                    className='img-center'
                                    alt='img'
                                  />
                                </Link>
                              </div>

                              <div className='col-sm-9 col-md-9 order-2'>
                                <Link
                                  className='mb-2 h5 text-capitalize company-title d-inline-block'
                                  to={'/centers/' + item.uniqueToken}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {item.displayName
                                    ? item.displayName
                                    : item.companyName}
                                </Link>
                                <div className='ml-3 mt-2'>
                                  <Tags
                                    centerTag={
                                      item.tagNameDetails.length > 5
                                        ? item.tagNameDetails.splice(0, 5)
                                        : item.tagNameDetails
                                    }
                                  />
                                </div>
                                <p className='address pt-1'>{item.cityState}</p>

                                <div>
                                  <h6 className='font-weight-bold'>Course</h6>
                                  <p>{item.relatedCourse}</p>
                                </div>
                                <div className='row align-items-center container mt-3'>
                                  <div className='d-inline'></div>
                                  <div className='d-inline ml-1'>
                                    {/* <a className='btn btn-sm btn-primary'> */}
                                    <Link
                                      className='btn btn-sm btn-primary'
                                      to={'/centers/' + item.uniqueToken}
                                    >
                                      View Details
                                    </Link>
                                    {/* </a> */}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='divider my-5 mr-5'></div>
                          </div>
                        );
                      })}
                    </>
                  )}
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination justify-content-end mr-2'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </div>
                {/* End of left */}
                {/* Right */}
                <div className='col-sm-5 col-md-5 p-1 order-2'>
                  <div className='col-sm-12 sticky'>
                    <div
                      style={{
                        height: '450px',
                        width: '100%',
                      }}
                    >
                      <GoogleMapReact
                        bootstrapURLKeys={{
                          key: 'AIzaSyBLqLVq7KdKBDl88uRRqMIBBuHtG4fhuM4',
                        }}
                        yesIWantToUseGoogleMapApiInternals
                        center={{
                          lat: this.state.defLatitude
                            ? parseFloat(this.state.defLatitude)
                            : this.state.latitude,
                          lng: this.state.defLongitude
                            ? parseFloat(this.state.defLongitude)
                            : this.state.longitude,
                        }}
                        zoom={
                          this.state.defLatitude || this.state.defLongitude
                            ? this.state.zoom
                            : 8
                        }
                        hoverDistance={30}
                      >
                        {this.state.directory.map((item, index) => {
                          return (
                            <Marker
                              showWindow
                              key={index}
                              index={index}
                              markerData={item}
                              showInfo={
                                index === this.state.markerIndex ? true : false
                              }
                              markerClose={this._onMarkerClose}
                              markerClick={this._onMarkerClick}
                              lat={item.latitude}
                              lng={item.longitude}
                            />
                          );
                        })}
                      </GoogleMapReact>
                    </div>
                    {/* <div
                        className='mt-4'
                        style={{
                          height: 150,
                          width: '100%',
                          backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        }}
                      >
                        <div
                          className='h6'
                          style={{
                            padding: 5,
                            backgroundColor: 'lightgray',
                            width: 40,
                          }}
                        >
                          Ads
                        </div>
                      </div> */}
                    {/* <div
                        className='mt-4'
                        style={{
                          height: 150,
                          width: '100%',
                          backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        }}
                      >
                        <div
                          className='h6'
                          style={{
                            padding: 5,
                            backgroundColor: 'lightgray',
                            width: 40,
                          }}
                        >
                          Ads
                        </div>
                      </div> */}
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
        <br />
        {!this.state.initial && <Footer />}
      </>
    );
  }
}
