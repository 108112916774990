import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../constants';
import Loading from '../components/Loading';
import ReactPaginate from 'react-paginate';
import GoogleMapReact from 'google-map-react';
import MetaTags from 'react-meta-tags';

import Tags from '../components/Tags';
import Marker from '../components/Marker';
import centerIcon from '../assets/default-center.png';
import Navbar from '../components/NavigationBar';
export default class SearchResult extends React.Component {
  constructor() {
    super();

    this.state = {
      searchResult: [],
      search: '',
      searchIndex: 0,
      loading: true,

      resultCount: 0,
      pageLoad: false,
      pageCount: 0,

      latitude: 5.4164,
      longitude: 100.3327,
      defLatitude: '',
      defLongitude: '',
      zoom: 11,
      markerIndex: '',
    };
  }

  componentDidMount() {
    console.log('searching... - ', this.props.match.params.search);
    this.setState({ search: this.props.match.params.search }, () => {
      Promise.all([this.getGeolocation(), this.getsearchResult()]);
    });
  }

  componentDidUpdate(prevProps, prevState) {}

  handleEnter(key) {
    if (key === 'Enter') {
      this.getsearchResult();
    }
  }

  getGeolocation() {
    if ('geolocation' in navigator) {
      console.log('Available');
      navigator.geolocation.getCurrentPosition((position) => {
        // console.log('geo :', position);
        console.log('Latitude is :', position.coords.latitude);
        console.log('Longitude is :', position.coords.longitude);
        this.setState({
          defLatitude: position.coords.latitude,
          defLongitude: position.coords.longitude,
        });
      });
    } else {
      console.log('Not Available');
    }
  }

  getsearchResult = () => {
    this.setState({ loading: true }, () => {
      if (this.state.search.length > 0) {
        axios
          .get(
            BASE_URL +
              'Api/GetCenterDirectoryList/V2?centerName=&searchingInput=' +
              this.state.search +
              '&PageIndex=' +
              this.state.searchIndex +
              '&PageSize=10'
          )
          .then((res) => {
            if (res.data.result.success) {
              console.log('searchresult -', res.data.data.length);
              this.setState(
                {
                  searchResult: res.data.data.centerDirectoryList,
                  pageCount: res.data.data.totalCount / 10,
                  resultCount: res.data.data.totalCount,
                  loading: false,
                },
                () => {
                  if (this.state.pageLoad || this.state.searchIndex > 0) {
                    this.setState({ pageLoad: false }, () => {
                      document.getElementById('search-home').scrollIntoView();
                    });
                  }
                }
              );
            }
          })
          .catch((err) => {
            this.setState({ loading: false }, () => {
              console.log('search error -', err);
            });
          });
      } else {
        this.setState({ loading: false }, () => {
          console.log('Search null');
        });
      }
    });
  };

  _onMarkerClick = (index) => {
    if (index) {
      this.setState({ markerIndex: index }, () => {
        console.log('info', this.state.markerIndex);
      });
    } else {
      this.setState({ markerIndex: '' }, () => {
        console.log('closing info', this.state.markerIndex);
      });
    }
  };

  handlePageClick = (data) => {
    console.log(data.selected);
    this.setState(
      { pageLoad: true, searchIndex: data.selected, searchResult: [] },
      () => {
        this.getsearchResult();
      }
    );
  };

  render() {
    return (
      <>
        <Navbar {...this.props} />
        <div className='container py-4 px-3 mt-5'>
          <section className='container my-5'>
            <div id='custom-search-input'>
              <div className='input-group col-md-12'>
                <input
                  type='text'
                  className='form-control input-lg'
                  placeholder='Search by center name or states'
                  onChange={(event) =>
                    this.setState({ search: event.target.value })
                  }
                  onKeyDown={(event) => this.handleEnter(event.key)}
                  style={{ borderColor: '#2f5ddb', borderWidth: 2 }}
                />
                <div
                  className='input-group-btn'
                  // style={{
                  //   backgroundColor: '#2f5ddb',
                  //   verticalAlign: 'center',
                  // }}
                >
                  <button
                    className='btn btn-info btn-lg'
                    type='button'
                    onClick={() => this.getsearchResult()}
                  >
                    <span
                      className='fa fa-search'
                      // style={{ color: 'white', backgroundColor: '#2f5ddb' }}
                    ></span>
                  </button>
                </div>
              </div>
            </div>
          </section>
          <section className='container'>
            <div className='my-4 form-inline container'>
              {'Home > Search Result > '}
              <div className='search-result pl-1'>
                {this.state.searchResult.length > 0
                  ? `${
                      this.state.resultCount > 0
                        ? this.state.resultCount
                        : this.state.searchResult.length
                    } items found for ${this.state.search}`
                  : `No result found for ${this.state.search}`}
              </div>
            </div>
          </section>
          <section>
            {this.state.loading ? (
              <Loading />
            ) : (
              <div id='search-home' className='row mt-4'>
                <div className=' col-sm-7 col-md-7 py-3 order-1'>
                  {this.state.searchResult.map((item, index) => {
                    return (
                      <div key={index} className='col-sm-12 col-md-12 mb-3'>
                        <div className='row'>
                          <div className='col-sm-3 col-md-3 order-1 text-center'>
                            <Link>
                              <img
                                src={
                                  item.fileLocation
                                    ? item.fileLocation
                                    : centerIcon
                                }
                                className='img-center'
                                alt='img'
                              />
                            </Link>
                          </div>
                          <div className='col-sm-9 col-md-9 order-2'>
                            <Link
                              className='mb-2 h5 text-capitalize company-title'
                              to={'/centers/' + item.uniqueToken}
                              style={{ cursor: 'pointer' }}
                            >
                              {item.displayName
                                ? item.displayName
                                : item.companyName}
                            </Link>

                            <p className='address pt-1'>{item.cityState}</p>

                            <div>
                              <h6 className='font-weight-bold'>Course</h6>
                              <p>{item.relatedCourse}</p>
                            </div>
                            <div className='row align-items-center container mt-3'>
                              <div className='d-inline'></div>
                              <div className='d-inline'>
                                {/* <a className='btn btn-sm btn-primary'> */}
                                <Link
                                  className='btn btn-sm btn-primary'
                                  to={'/centers/' + item.uniqueToken}
                                >
                                  View Details
                                </Link>
                                {/* </a> */}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='divider my-5 mr-5'></div>
                      </div>
                    );
                  })}
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={this.state.pageCount}
                    forcePage={this.state.searchIndex}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination justify-content-end mr-2'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </div>
                <div className='col-sm-5 col-md-5 p-1 order-2'>
                  <div className='col-sm-12 sticky'>
                    <div
                      style={{
                        height: '450px',
                        width: '100%',
                      }}
                    >
                      <GoogleMapReact
                        bootstrapURLKeys={{
                          key: 'AIzaSyBLqLVq7KdKBDl88uRRqMIBBuHtG4fhuM4',
                        }}
                        yesIWantToUseGoogleMapApiInternals
                        center={{
                          lat: this.state.defLatitude
                            ? parseFloat(this.state.defLatitude)
                            : this.state.latitude,
                          lng: this.state.defLongitude
                            ? parseFloat(this.state.defLongitude)
                            : this.state.longitude,
                        }}
                        zoom={
                          this.state.defLatitude || this.state.defLongitude
                            ? this.state.zoom
                            : 8
                        }
                        hoverDistance={30}
                      >
                        {this.state.searchResult.map((item, index) => {
                          return (
                            <Marker
                              showWindow
                              key={index}
                              index={index}
                              markerData={item}
                              showInfo={
                                index === this.state.markerIndex ? true : false
                              }
                              markerClose={this._onMarkerClose}
                              markerClick={this._onMarkerClick}
                              lat={item.latitude}
                              lng={item.longitude}
                            />
                          );
                        })}
                      </GoogleMapReact>
                    </div>
                    {/* <div
                        className='mt-4'
                        style={{
                          height: 150,
                          width: '100%',
                          backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        }}
                      >
                        <div
                          className='h6'
                          style={{
                            padding: 5,
                            backgroundColor: 'lightgray',
                            width: 40,
                          }}
                        >
                          Ads
                        </div>
                      </div> */}
                    {/* <div
                        className='mt-4'
                        style={{
                          height: 150,
                          width: '100%',
                          backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        }}
                      >
                        <div
                          className='h6'
                          style={{
                            padding: 5,
                            backgroundColor: 'lightgray',
                            width: 40,
                          }}
                        >
                          Ads
                        </div>
                      </div> */}
                  </div>
                </div>
              </div>
            )}
          </section>
        </div>
      </>
    );
  }
}
