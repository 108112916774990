import './App.css';

import { Provider } from 'react-redux';
import store, { setLanguage } from './redux/app-redux';
import Main from './Main';
import TagManager from 'react-gtm-module'


const tagManagerArgs = {
  gtmId: 'GTM-NNDQFG3'
}

TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <Provider store={store}>
      <Main />
    </Provider>
  );
}

export default App;
